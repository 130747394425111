import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserHrDocumentService} from "services/userHrDocumentService";
import {UserHrDocument} from "types/userHrDocument";
import {SearchType} from "types/search";

export class UserHrDocumentThunks {
    static index = createAsyncThunk(
        'userHrDocument/index',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{ [key: string]: any }>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await UserHrDocumentService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );

    static create = createAsyncThunk(
        'userHrDocument/create',
        async (record: UserHrDocument) => {
            return await UserHrDocumentService.create(record);
        }
    );

    static show = createAsyncThunk(
        'userHrDocument/show',
        async (id: string) => {
            return await UserHrDocumentService.show(id);
        }
    );

    static update = createAsyncThunk(
        'userHrDocument/update',
        async (record: UserHrDocument) => {
            console.log('record', record);
            return await UserHrDocumentService.update(record);
        }
    );

    static delete = createAsyncThunk(
        'userHrDocument/delete',
        async (id: string) => {
            return await UserHrDocumentService.delete(id);
        }
    );
}
