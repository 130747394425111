import React from "react";

interface TextInputProps {
    id: string;
    type: string;
    label: string;
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void | undefined;
    error?: string | undefined;
    placeholder?: string;
    tabIndex?: number;
    disabled?: boolean;
    addon?: string;
}

const GroupInput: React.FC<TextInputProps> = ({
                                                  id,
                                                  type,
                                                  label,
                                                  value,
                                                  onChange,
                                                  onBlur,
                                                  error,
                                                  placeholder,
                                                  tabIndex,
                                                  disabled,
                                                  addon,
                                              }) => {
    return (
        <div className="flex flex-col items-start gap-[0.625rem] w-44 ">
            <label
                htmlFor={id}
                className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                {label}
            </label>
            <div className="flex items-center border border-slate-200 shadow rounded-lg overflow-hidden w-full">
                <input
                    type={type}
                    id={id}
                    data-testid={`${id}-field`}
                    value={value}
                    onChange={onChange}
                    className={`w-14 py-2.5 px-3 block focus:outline-cyan-400 border-none text-sm text-gray-500 font-light placeholder:text-gray-400 
                                disabled:opacity-50 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300
                                dark:bg-transparent dark:text-neutral-300 dark:placeholder:text-white/60  
                                ${error ? "border-red-500" : "border-gray-200"}`}
                    placeholder={placeholder}
                    required
                    aria-describedby={`${id}-error`}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    onBlur={onBlur}
                />
                {addon && (
                    <span className="bg-gray-100 text-gray-500 pr-4 pl-[1.17rem] py-3 text-xs">
                        {addon}
                    </span>
                )}
            </div>
            {error && (
                <p className="text-xs text-red-600 " id={`${id}-error`}>
                    {error}
                </p>
            )}
        </div>
    );
};

export default GroupInput;
