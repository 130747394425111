import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Role, RoleInterface} from "types/role";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {RoleThunks} from "features/role/roleThunks";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import TrashIcon from "assets/images/icons/TrashIcon";
import DotsMenu from "components/DotsMenu";
import {LocationThunks} from "../location/locationThunks";
import {Location} from "../../types/location";
import {useDropdownActionMenu} from "../../hooks/useDropdownActionMenu";
import {setActiveTab} from "../location/locationSlice";
import CheckCircleIcon from "../../assets/images/icons/CheckCircleIcon";
import XCircleIcon from "../../assets/images/icons/XCircleIcon";


interface RoleTableProps {
    openDrawer: (id?: string) => void;
    handleNewRoleOpenDrawer: () => void;
}

const RoleTable: React.FC<RoleTableProps> = ({openDrawer, handleNewRoleOpenDrawer}) => {
    const roles = useSelector((state: RootState) => state.role.roles);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.role.pagy);
    const activeTab = useSelector((state: RootState) => state.role.activeTab);
    const {
        setDotsMenuIsOpen
    } = useDropdownActionMenu();

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        selectable: true,
        description: true,
        status: true,
        created: true
    });

    interface VisibleColumnsType {
        name: boolean,
        selectable: boolean,
        description: boolean,
        status: boolean
        created: boolean
    }

    const fetchUserData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab === 'Active' ? 'Active' : 'Inactive'
            };
        } else {
            search.where.status = {
                operator: '==',
                value: ''
            }
        }
        await dispatch(RoleThunks.index({ page, search, sortField, sortDirection, rowsPerPage }));
    };

    const columnOptions = [
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Description', value: 'description', isVisible: visibleColumns.description},
        {label: 'Created', value: 'created', isVisible: visibleColumns.created},
        {label: 'Selectable', value: 'selectable', isVisible: visibleColumns.selectable},
    ];

    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
    ];


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id?: string) => async () => {
        if (id) {
            await dispatch(RoleThunks.show(id));
        }
        openDrawer();
    }
    const handleDelete = async (id: string) => {
        await dispatch(RoleThunks.delete(id));

    }

    const handleEdit = async (id: string) => {
        await dispatch(RoleThunks.show(id));
        openDrawer();

    }
    const handleDeactivate = (roleId: string) => {
        const role = new Role({id: roleId, status: 'inactive'})
        dispatch(RoleThunks.update(role) as any);
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (roleId: string) => {
        const role = new Role({id: roleId, status: 'active'})
        dispatch(RoleThunks.update(role) as any);
        setDotsMenuIsOpen(null);
    };

    return (

        <Table fetchData={fetchUserData}
               pagy={pagy}
               searchable={true}
               pageable={true}
               recordsName={'Roles'}
               activeTab={activeTab}
               setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
               tabs={['All', 'Active', 'Inactive']}
               columns={true}
               columnOptions={columnOptions}
               onColumnToggle={handleColumnToggle}
               searchByOptions={searchByOptions}
               blueButton={<BlueButton onClick={handleNewRoleOpenDrawer} label="" icon={<PlusIcon/>}/>}>
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                                       dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
                {visibleColumns.created && <TableHeader label="Created" sortBy="created"/>}
                {visibleColumns.selectable && <TableHeader label="Selectable" sortBy="selectable"/>}

            </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">

            {roles.length > 0 && (
                roles.map((roleData: RoleInterface) => {
                    const role = new Role(roleData);
                    const userOptions = [];
                    userOptions.push({
                        label: "Edit",
                        onClick: () => handleEdit(role.id),
                        hoverClass: "hover:bg-cyan-100",
                    });
                    if (role.status === "Active") {
                        userOptions.push({
                            label: "Deactivate",
                            onClick: () => handleDeactivate(role.id),
                            hoverClass: "hover:bg-red-100 hover:text-red-800",
                        });
                    }
                    if (role.status === "Inactive") {
                        userOptions.push({
                            label: "Activate",
                            onClick: () => handleReactivate(role.id),
                            hoverClass: "hover:bg-cyan-100",
                        });
                    }
                    if(!role.isOwner()) {
                        userOptions.push({
                            label: "Delete",
                            icon: <TrashIcon/>,
                            onClick: () => handleDelete(role.id),
                            hoverClass: "hover:bg-red-100 hover:text-red-800",
                        });
                    }

                    return (
                        <TableRow
                            key={role.id}
                        >
                            <td scope="col" className="pt-3.5 flex justify-center">
                                <input
                                    type="checkbox"
                                    className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800
                                               dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    onClick={(e) => e.stopPropagation()}/>
                            </td>
                            {visibleColumns.name && <TableCell>
                                <div className="relative group py-0.5">
                                    <div className="w-full flex items-center gap-x-3">
                                        <div className="flex-grow">
                                        <span
                                            className="text-sm cursor-pointer font-medium underline text-cyan-600 dark:text-neutral-200"
                                            onClick={handleOpenDrawer(role.id)}
                                        >

                                            {role.nameAlias}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>}
                            {visibleColumns.status && (
                                <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${role.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {role.status === 'Active' ? <CheckCircleIcon className="mr-2"/> :
                                    <XCircleIcon className="mr-2"/>}

                                <div className="pl-1">
                                    {role.status === 'Active' ? role.status : 'Inactive'}
                                </div>
                            </span>
                                </TableCell>)}
                            {visibleColumns.description && (
                                <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                    <div style={{ maxWidth: '150px' }} className="whitespace-nowrap overflow-hidden text-ellipsis">
                                        {role.description}
                                    </div>
                                </TableCell>
                            )}
                            {visibleColumns.created && <TableCell>{role.createdAt}</TableCell>}
                            {visibleColumns.selectable && <TableCell>{role.selectable ? 'Yes' : 'No'}</TableCell>}
                            <TableCell>
                                <DotsMenu options={userOptions}/>
                            </TableCell>
                        </TableRow>
                    );
                })
            )}
            </tbody>
        </Table>

    );
};

export default RoleTable;
