import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserTraining} from "types/userTraining";
import {UserTrainingService} from "services/userTrainingService";
import {SearchType} from "types/search";

export class UserTrainingThunks {
    static index = createAsyncThunk(
        'userTraining/index',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{ [key: string]: any }>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await UserTrainingService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );

    static create = createAsyncThunk(
        'userTraining/create',
        async (record: UserTraining) => {
            return await UserTrainingService.create(record);
        }
    );

    static show = createAsyncThunk(
        'userTraining/show',
        async (id: string) => {
            return await UserTrainingService.show(id);
        }
    );

    static update = createAsyncThunk(
        'userTraining/update',
        async (record: UserTraining) => {
            return await UserTrainingService.update(record);
        }
    );

    static delete = createAsyncThunk(
        'userTraining/delete',
        async (id: string) => {
            return await UserTrainingService.delete(id);
        }
    );
}
