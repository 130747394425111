import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import SubmitButton from "components/SubmitButton";
import PencilSquareIcon from "assets/images/icons/PencilSquareIcon";
import {HrDocumentThunks} from "features/hrDocument/hrDocumentThunks";
import {setIsEditing} from "features/hrDocument/hrDocumentSlice";
import {HrDocument} from "types/hrDocument";
import DescriptionInput from "../../components/inputs/DescriptionInput";


interface HrDocumentFormProps {
    id?: string;
    closeDrawer: () => void;
}

const HrDocumentForm: React.FC<HrDocumentFormProps> = ({id, closeDrawer}) => {
    const hrDocument = useSelector((state: RootState) => state.hrDocument.hrDocument);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedHrDocument, setUpdatedHrDocument] = useState({...hrDocument});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid =
            updatedHrDocument.name

            setIsSaveDisabled(!isFormValid);
    }, [
        updatedHrDocument,
    ]);

    useEffect(() => {
        setUpdatedHrDocument({...hrDocument})
        setIsSaveDisabled(!hrDocument?.nameAlias);
    }, [hrDocument]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedHrDocument({...updatedHrDocument, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        console.log(updatedHrDocument)
        e.preventDefault();
        if (hrDocument?.id) {
            await dispatch(HrDocumentThunks.update(new HrDocument(updatedHrDocument))).then(() => closeDrawer());
        } else {
            await dispatch(HrDocumentThunks.create(new HrDocument(updatedHrDocument))).then(() => closeDrawer());
        }
    };

    const handleClose = () => {
        dispatch(setIsEditing(false));
        closeDrawer();
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-[158px] pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                    <div className=" mx-6 self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                            {hrDocument?.id ? 'Edit HR Documents' : 'Create HR Documents'}
                        </div>
                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {hrDocument?.id ? 'Edit' : 'Create '} HR Documents
                        </div>
                    </div>
                </div>
                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full">
                                    <TextInput
                                        id="name"
                                        type="text"
                                        label="Name"
                                        value={updatedHrDocument?.name}
                                        placeholder=""
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="w-full">
                                    <DescriptionInput
                                        id="description"
                                        type="text"
                                        label="Description"
                                        value={updatedHrDocument?.description}
                                        placeholder=""
                                        onChange={handleChange}
                                    />
                                </div>

                                </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={(hrDocument?.id ? 'Save' : 'Create new') + ' HR Document'}
                                      disabled={isSaveDisabled}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={handleClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
)
    ;
};

export default HrDocumentForm;
