import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {LocationThunks} from "./locationThunks";
import {Location} from "types/location";

export interface LocationState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    locations?: any;
    pagy?: any;
    location?: any;
    isEditing?: boolean;
    activeTab?: string;
}

const initialState: LocationState = {
    status: 'idle',
    error: null,
    locations: [],
    location: {},
    pagy: {},
    isEditing: false,
    activeTab: 'All'
};

const LocationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(LocationThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(LocationThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.location = action.payload;
                state.locations = [...state.locations, action.payload];
                showToast('Location created successfully', "success");
            })
            .addCase(LocationThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(LocationThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(LocationThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedLocation = action.payload;
                state.location = updatedLocation;

                const index = state.locations.findIndex((location: Location) => location.id === updatedLocation.id);
                if (index !== -1) {
                    state.locations[index] = updatedLocation;
                }

                showToast('Location updated successfully', "success");
            })
            .addCase(LocationThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(LocationThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(LocationThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.location = action.payload;
            })
            .addCase(LocationThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(LocationThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(LocationThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.pagy = action.payload.pagy;
                state.locations = action.payload.data;
            })
            .addCase(LocationThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(LocationThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(LocationThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.locations = state.locations.filter((location:any) => location.id !== action.payload);
                showToast('Location deleted successfully', "success");
            })
            .addCase(LocationThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setLocation, setIsEditing, setActiveTab} = LocationSlice.actions;
export {initialState as locationInitialState};
export default LocationSlice.reducer;
