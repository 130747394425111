import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {selectUserCredentials} from "features/auth/authSlice";
import usePinInput from "hooks/usePinInput";
import {showToast} from "components/ToastContainer";
import SubmitButton from "components/SubmitButton";
import ArrowRightIcon from "assets/images/icons/ArrowRightIcon";
import ArrowLeftIcon from "assets/images/icons/ArrowLeftIcon";
import {User} from "types/user";
import {UserThunks} from "features/user/userThunks";

const PinSetUpPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const userCredentials = useSelector(selectUserCredentials);
    const status = useSelector((state: RootState) => state.auth.status);
    const invitation = useSelector((state: RootState) => state.userInvitation.invitation);
    const params = useParams();

    const {
        firstInputRef,
        pin,
        error,
        isFormValid,
        handlePinChange,
        handleKeyDown,
        handlePaste,
        setError,
    } = usePinInput({initialPinLength: 4});

    useEffect(() => {
        if (invitation === null || invitation.status !== 'Pending') {
            navigate(`/accept-invitation/${params.token}`);
        }
    }, [invitation, navigate, params.token]);

    useEffect(() => {
        firstInputRef.current?.focus();
    }, [userCredentials, navigate]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        const fullPin = pin.join('');

        if (userCredentials === null || params.token === undefined) {
            showToast('Invalid invitation link', 'error');
            navigate('/login');
            return;
        }

        try {
            const user = new User({
                email: invitation?.email,
                firstName: invitation?.firstName,
                lastName: invitation?.lastName,
                phoneNumber: invitation?.phoneNumber
            });
            await dispatch(UserThunks.register({
                user,
                password: userCredentials.password,
                token: params.token,
                pinCode: fullPin,
                signature: userCredentials.signature,
                credentials: userCredentials.credentials
            })).then((resultAction) => {
                if (UserThunks.register.fulfilled.match(resultAction)) {
                    showToast('User created successfully', 'success');
                    navigate('/confirm-email');
                }
            });
        } catch (err) {
        }
    };

    return (
        <div className="flex flex-col justify-between">
            <div className="flex-grow flex flex-col md:pr-20 md:pl-20 pt-20 max-w-120 md:min-w-96 sm:min-w-64">
                <div className="w-80">
                    <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
                        Set a lock out PIN
                    </div>
                    <div className="text-sm font-light text-slate-400 font-['Inter']">
                        <span className="leading-tight">For security and compliance, the system will lock your screen after a period of inactivity.</span>
                    </div>
                    <div className="text-sm font-light text-slate-400 font-['Inter'] mt-4">
                        <span className="leading-tight">Set a PIN below to quickly unlock your screen and continue your work.</span>
                    </div>
                    <form>
                        <div className="w-full">
                            <div className="w-80 flex flex-cols-4 justify-start gap-3">
                                {pin.map((data, index) => (
                                    <input
                                        data-hs-pin-input
                                        key={index}
                                        id={`pin-input-${index}`}
                                        type="text"
                                        maxLength={1}
                                        ref={index === 0 ? firstInputRef : null}
                                        className={`mt-12 w-9 h-10 shadow border bg-white text-center rounded-lg text-sm placeholder:text-gray-400 outline-cyan-400 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600 ${error ? "border-red-500" : "border-slate-200"}`}
                                        value={data}
                                        onChange={(e) => handlePinChange(e.target.value, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onPaste={handlePaste}
                                        onFocus={(e) => e.target.select()}
                                    />
                                ))}
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <footer className="grid grid-rows-2 justify-center w-full mt-8">
                <div className="flex justify-center items-center w-full gap-3">
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center py-3 px-2.5 text-cyan-500 border border-cyan-400 rounded-lg hover:bg-cyan-50 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                    >
                        <span className="mx-1">
                            <ArrowLeftIcon />
                        </span>
                    </button>
                    <SubmitButton
                        className={"w-[330px] py-2.5 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"}
                        onClick={handleSubmit}
                        label={<>{'Continue'} <ArrowRightIcon /></>}
                        disabled={status === 'loading' || !isFormValid}
                    />
                </div>
                <div className="flex justify-center items-center ">
                    <div className="flex items-center gap-3">
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-cyan-400 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default PinSetUpPage;
