      import {createAsyncThunk} from "@reduxjs/toolkit";
      import {Location} from "types/location";
      import {LocationService} from "services/locationService";
      import {SearchType} from "types/search";

      export class LocationThunks {
          static index = createAsyncThunk(
              'location/index',
              async ({
                         page,
                         search,
                         sortField,
                         sortDirection,
                         rowsPerPage
                     }: {
                  page?: number,
                  search?: SearchType<{ [key: string]: any }>,
                  sortField?: string,
                  sortDirection?: string,
                  rowsPerPage?: number
              } = {}) => {
                  return await LocationService.index(page, search, sortField, sortDirection, rowsPerPage);
              }
          );

          static reactivate = createAsyncThunk(
              'location/activate',
              async (id: string) => {
                  return await LocationService.reactivate(id);
              }
          );
            static deactivate = createAsyncThunk(
                'location/deactivate',
                async (id: string) => {
                    return await LocationService.deactivate(id);
                }
            );
      
          static create = createAsyncThunk(
              'location/create',
              async (record: Location) => {
                  return await LocationService.create(record);
              }
          );
      
          static show = createAsyncThunk(
              'location/show',
              async (id: string) => {
                  return await LocationService.show(id);
              }
          );
      
          static update = createAsyncThunk(
              'location/update',
              async (record: Location) => {
                  return await LocationService.update(record);
              }
          );
      
          static delete = createAsyncThunk(
              'location/delete',
              async (id: string) => {
                  return await LocationService.delete(id);
              }
          );
      }
