    import apiClient from "services/apiClient";
    import {Payer} from "types/payer";
    import {SearchType} from "types/search";
    import {buildUrlWithParams} from "utils/apiUtils";
    
    export class PayerService {
        static async index(page: number, search: SearchType<{ [key: string]: any}>, sortField: string, sortDirection: string, rowsPerPage: number) {
            const url = buildUrlWithParams({
                baseUrl: 'api/v1/payers',
                page: page,
                search: search,
                sortField: sortField,
                sortDirection: sortDirection,
                rowsPerPage: rowsPerPage,
            });

            const response = await apiClient.get(url);
            return response.data;
        }
    
        static async create(record: Payer) {
            const response = await apiClient.post("api/v1/payers", {payer: record.toJson()});
            return response.data;
        }
    
        static async show(id: string) {
            const response = await apiClient.get(`api/v1/payers/${id}`);
            return response.data;
        }
    
        static async update(record: Payer) {
            const response = await apiClient.patch(`api/v1/payers/${record.id}`, {payer: record.toJson()});
            return response.data;
        }
    
        static async delete(id: string) {
            const response = await apiClient.delete(`api/v1/payers/${id}`);
            if (response.status === 204) {
                return id;
            }
            return response.data;
        }
    }
