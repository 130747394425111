import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Role} from "types/role";
import Table from "components/table/Table";
import {UserThunks} from "features/user/userThunks";
import {User} from "types/user";
import {SearchType} from "types/search";
import {setActiveTab, setIsEditing} from "features/user/userSlice";
import SSNDisplay from "components/SSNDisplay";
import PhoneNumberDisplay from "components/PhoneNumberDisplay";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import BlockedIcon from "assets/images/icons/BlockedIcon";
import LockedIcon from "assets/images/icons/LockedIcon";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";
import CircleDashedIcon from "assets/images/icons/CircleDashedIcon";
import {Credential} from "../../types/credential";
import TrashIcon from "assets/images/icons/TrashIcon";

const UserTable: React.FC<{
    openDrawer: () => void,
    openNewDrawer: () => void,
    isNewDrawerOpen: boolean
}> = ({openDrawer, openNewDrawer, isNewDrawerOpen}) => {
    const users = useSelector((state: RootState) => state.user.users);
    const selectRoles = useSelector((state: RootState) => state.role.selectableRoles);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.user.pagy);
    const activeTab = useSelector((state: RootState) => state.user.activeTab);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();

    interface VisibleColumnsType {
        employeeId: boolean;
        name: boolean;
        workEmail: boolean;
        personalEmail: boolean;
        ssn: boolean;
        phoneNumber: boolean;
        roles: boolean;
        status: boolean;
        location: boolean;
        credentials: boolean;
        lastLogin: boolean;
        supervisors: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
        employeeId: true,
        name: true,
        workEmail: true,
        personalEmail: true,
        ssn: true,
        phoneNumber: true,
        roles: true,
        status: true,
        location: true,
        credentials: true,
        lastLogin: true,
        supervisors: true,
    });

    const columnOptions = [
        {label: 'ID', value: 'employeeId', isVisible: visibleColumns.employeeId},
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Work email', value: 'workEmail', isVisible: visibleColumns.workEmail},
        {label: 'Personal email', value: 'personalEmail', isVisible: visibleColumns.personalEmail},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'SSN', value: 'ssn', isVisible: visibleColumns.ssn},
        {label: 'Phone number', value: 'phoneNumber', isVisible: visibleColumns.phoneNumber},
        {label: 'Roles', value: 'roles', isVisible: visibleColumns.roles},
        {label: 'Locations', value: 'location', isVisible: visibleColumns.location},
        {label: 'Certifications', value: 'credentials', isVisible: visibleColumns.credentials},
        {label: 'Supervisors', value: 'supervisors', isVisible: visibleColumns.supervisors},
        {label: 'Last login', value: 'lastLogin', isVisible: visibleColumns.lastLogin},

    ];

    const searchByOptions = [
        {label: 'Employee ID', value: 'id', operator: '=='},
        {label: 'Name', value: 'name', operator: 'like'},
        {label: 'Email', value: 'email', operator: 'like'},
        {label: 'SSN', value: 'ssn', operator: 'like'},
    ];

    useEffect(() => {
        if (!isNewDrawerOpen) {
            fetchUserData({page: 1, search: {where: {}}, sortField: 'id', sortDirection: 'desc', rowsPerPage: 10});
        }
    }, [isNewDrawerOpen]);

    const fetchUserData = async ({page, search, sortField, sortDirection, rowsPerPage,}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab.toLowerCase()
            };
        } else {
            delete search.where.status;
        }
        search.where.user_type = {
            operator: '==',
            value: 'user'
        }

        await dispatch(UserThunks.index({page, search, sortField, sortDirection, rowsPerPage,}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column as keyof VisibleColumnsType]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id?: string) => async () => {
        dispatch(setIsEditing(false));
        if (id) {
            await dispatch(UserThunks.show(id))
        }
        openDrawer();
    }
    const handleDeactivate = (userId: string) => {
        const user = new User({id: userId, status: 'inactive'})
        dispatch(UserThunks.update(user) as any);
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (userId: string) => {
        const user = new User({id: userId, status: 'active'})
        dispatch(UserThunks.update(user) as any);
        setDotsMenuIsOpen(null);
    };

    const handleUnlock = (userId: string) => {
        dispatch(UserThunks.unlock(userId) as any);
        setDotsMenuIsOpen(null);
    };

    const handleUnblock = (userId: string) => {
        dispatch(UserThunks.unblock(userId) as any);
        setDotsMenuIsOpen(null);
    };

    const handleEdit = (userId: string) => {
        dispatch(setIsEditing(true));
        dispatch(UserThunks.show(userId));
        openDrawer();
    };

    const handleDelete = (userId: string) => {
        dispatch(UserThunks.delete(userId) as any);
        setDotsMenuIsOpen(null);

    };

    return (
        <Table
            fetchData={fetchUserData}
            pagy={pagy}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive', 'Invitations']}
            recordsName={'Employees'}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={openNewDrawer} label={''} icon={<PlusIcon/>}/>}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.employeeId && <TableHeader label="ID" sortBy="id"/>}
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.workEmail && <TableHeader label="Work email" sortBy="email"/>}
                {visibleColumns.personalEmail && <TableHeader label="Personal email" sortBy="personalEmail"/>}
                {visibleColumns.status &&
                    <TableHeader label="Status" sortBy={activeTab === 'All' ? "status" : undefined}/>}
                {visibleColumns.ssn && <TableHeader label="SSN" sortBy="ssn"/>}
                {visibleColumns.phoneNumber && <TableHeader label="Phone number" sortBy="phoneNumber"/>}
                {visibleColumns.roles && <TableHeader label="Roles" sortBy="role"/>}
                {visibleColumns.location && <TableHeader label="Locations" sortBy="location"/>}
                {visibleColumns.credentials && <TableHeader label="Certifications" sortBy="credentials"/>}
                {visibleColumns.supervisors && selectRoles.length > 0 && (
                    selectRoles.map((role: Role) => (
                        <TableHeader key={role.id} label={role.nameAlias || role.name}
                                     sortBy={`supervisor_${role.name}`}/>
                    ))
                )}
                {visibleColumns.lastLogin && <TableHeader label="Last login" sortBy="lastLogin"/>}
                <TableHeader label=""/>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {users.length > 0 && (
                users.map((user: User) => {
                        const userOptions = [];
                        userOptions.push({
                            label: "Edit",
                            onClick: () => handleEdit(user.id),
                            hoverClass: "hover:bg-cyan-100",
                        });

                        if (user.status === "Active") {
                            userOptions.push({
                                label: "Deactivate",
                                onClick: () => handleDeactivate(user.id),
                                hoverClass: "hover:bg-red-100 hover:text-red-800",
                            });
                        }
                        if (user.status === "Inactive") {
                            userOptions.push({
                                label: "Activate",
                                onClick: () => handleReactivate(user.id),
                                hoverClass: "hover:bg-cyan-100",
                            });
                        }
                        if (user.locked) {
                            userOptions.push({
                                label: "Unlock",
                                onClick: () => handleUnlock(user.id),
                                hoverClass: "hover:bg-cyan-100",
                            });
                        }
                        if (user.blocked) {
                            userOptions.push({
                                label: "Unblock",
                                onClick: () => handleUnblock(user.id),
                                hoverClass: "hover:bg-cyan-100",
                            });

                        }
                        userOptions.push({
                            label: "Delete",
                            onClick: () => handleDelete(user.id),
                            icon: <TrashIcon/>,
                            hoverClass: "hover:bg-red-100 hover:text-red-800",
                        });
                        return (
                            <TableRow
                                key={user.id}
                            >
                                <td scope="col" className="pt-3.5 flex justify-center">
                                    <input
                                        type="checkbox"
                                        className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                        onClick={(e) => e.stopPropagation()}/>
                                </td>
                                {visibleColumns.employeeId && <TableCell>
                                    <div className="flex justify-between items-center">
                                        <div>{user.id}</div>
                                    </div>
                                </TableCell>}
                                {visibleColumns.name && <TableCell>
                                    <div className=" relative group">
                                        <div className="w-full flex items-center gap-x-3">
                                            <div className="flex-grow">
                                        <span
                                            className="text-sm cursor-pointer font-medium underline text-cyan-600 dark:text-neutral-200"
                                            onClick={handleOpenDrawer(user.id)}
                                        >
                                            {user.firstName} {user.lastName}
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>}
                                {visibleColumns.workEmail && <TableCell>{user.email}</TableCell>}
                                {visibleColumns.personalEmail && <TableCell>{user.personalEmail}</TableCell>}
                                {visibleColumns.status && (
                                    <TableCell>
                             <span
                                 className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                            ${user.status === 'Active' && !user.locked && !user.blocked ? 'bg-green-100 text-green-800' : ''}
                            ${user.status === 'Inactive' && !user.locked && !user.blocked ? 'bg-gray-100 text-gray-800' : ''}
                            ${user.status === 'Onboarding' && !user.locked && !user.blocked ? 'bg-yellow-100 text-yellow-800' : ''}
                            ${user.locked ? 'bg-red-100 text-red-800' : ''}
                            ${user.blocked ? 'bg-red-100 text-red-800' : ''}
                        `}
                             >
                        {user.status === 'Active' && !user.locked && !user.blocked &&
                            <CheckCircleIcon className="mr-2"/>}
                                 {user.status === 'Inactive' && !user.locked && !user.blocked &&
                                     <XCircleIcon className="mr-2"/>}
                                 {user.status === 'Onboarding' && !user.locked && !user.blocked &&
                                     <CircleDashedIcon className="mr-2"/>}
                                 {user.blocked && <BlockedIcon className="mr-2"/>}
                                 {user.locked && <LockedIcon className="mr-2"/>}
                                 <div className="pl-1">
                            {user.status === 'Active' && !user.locked && !user.blocked && user.status}
                                     {user.status === 'Inactive' && !user.locked && !user.blocked && 'Inactive'}
                                     {user.status === 'Onboarding' && !user.locked && !user.blocked && 'Onboarding'}
                                     {user.blocked && 'Blocked'}
                                     {user.locked && 'Locked'}
                        </div>
                     </span>
                                    </TableCell>)}
                                {visibleColumns.ssn && <TableCell><SSNDisplay value={user.ssn || ''}/></TableCell>}
                                {visibleColumns.phoneNumber &&
                                    <TableCell><PhoneNumberDisplay value={user.phoneNumber || ''}/></TableCell>}
                                {visibleColumns.roles && <TableCell>{user.role?.nameAlias}</TableCell>}
                                {visibleColumns.location && <TableCell>{user.location}</TableCell>}
                                {visibleColumns.credentials &&
                                    <TableCell>{user.credentials.map((credential: Credential) => credential.name).join(', ')}</TableCell>}
                                {visibleColumns.supervisors && selectRoles.length > 0 && (
                                    selectRoles.map((role: Role) => (
                                        <TableCell key={role.id}>
                                            {user.supervisors?.find(s => s.role.id === role.id)?.user.name}
                                        </TableCell>
                                    ))
                                )}
                                {visibleColumns.lastLogin && <TableCell>{user.lastLogin}</TableCell>}
                                <TableCell>
                                    <DotsMenu options={userOptions}/>
                                </TableCell>
                            </TableRow>
                        )
                    }
                ))}
            </tbody>
        </Table>
    );
};

export default UserTable;
