import React, {useEffect, useState, ReactNode, useRef} from "react";
import {SortingProvider, useSorting} from "hooks/useTableSorting";
import SearchIcon from "assets/images/icons/SearchIcon";
import ChevronLeftIcon from "assets/images/icons/ChevronLeftIcon";
import ChevronRightIcon from "assets/images/icons/ChevronRightIcon";
import BarsDownMenuIcon from "assets/images/icons/BarsDownMenuIcon";
import ChevronDownIcon from "assets/images/icons/ChevronDownIcon";
import {SearchType} from "types/search";
import ColumnsIcon from "assets/images/icons/ColumnsIcon";

interface TableProps {
    fetchData: (params: {
        page: number;
        search: SearchType<any>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number;

    }) => Promise<void>;
    pagy: {
        count: number;
        pages: number;
    };
    searchByOptions?: any[];
    searchable?: boolean;
    pageable?: boolean;
    tabs?: string[];
    children: ReactNode;
    activeTab?: string;
    setActiveTab?: (tab: string) => void;
    recordsName?: string;
    blueButton?: ReactNode;
    columns?: boolean;
    columnOptions?: any[];
    onColumnToggle?: (column: string) => void;
    showColumnsButton?: boolean;
}

const Table: React.FC<TableProps> = (
    {
        fetchData,
        pagy,
        children,
        searchable = true,
        pageable = true,
        tabs,
        searchByOptions = [],
        recordsName = 'Records',
        activeTab,
        setActiveTab,
        blueButton,
        columns = true,
        columnOptions,
        onColumnToggle,
        showColumnsButton = true,

    }) => {
    const [page, setPage] = useState(1);
    const [searchBy, setSearchBy] = useState(searchByOptions[0]);
    const searchInitialState: SearchType<{
        [key: string]: { operator: string; value: string }
    }> = searchByOptions.length > 0
        ? {where: {[searchBy.value]: {operator: searchBy.operator, value: ""}}}
        : {where: {'id': {operator: '==', value: ''}}};
    const [search, setSearch] = useState<SearchType<{
        [key: string]: { operator: string; value: string }
    }>>(searchInitialState);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {sortField, sortDirection} = useSorting();
    const [searchByIsOpen, setSearchByIsOpen] = useState(false);
    const [rowsPerPageIsOpen, setRowsPerPageIsOpen] = useState(false);
    const searchByButtonRef = useRef<HTMLButtonElement>(null);
    const searchByDropdownRef = useRef<HTMLDivElement>(null);
    const rowsPerPageButtonRef = useRef<HTMLButtonElement>(null);
    const rowsPerPageDropdownRef = useRef<HTMLDivElement>(null);
    const columnsRef = useRef<HTMLButtonElement>(null);
    const columnsDropdownRef = useRef<HTMLDivElement>(null);
    const [columnsIsOpen, setColumnsIsOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

    useEffect(() => {
        fetchData({page, search, sortField, sortDirection, rowsPerPage});
    }, [page, search, sortField, sortDirection, rowsPerPage, activeTab]);

    useEffect(() => {
        if (pagy?.pages < page) {
            setPage(pagy.pages);
        }
    }, [pagy]);

    const toggleDropdown = (dropdown: 'searchBy' | 'rowsPerPage' | 'columns', event: React.MouseEvent<HTMLButtonElement>) => {
        if (dropdown === 'searchBy') {
            setSearchByIsOpen(!searchByIsOpen);
            setRowsPerPageIsOpen(false);
            setColumnsIsOpen(false);
        } else if (dropdown === 'rowsPerPage') {
            setRowsPerPageIsOpen(!rowsPerPageIsOpen);
            setSearchByIsOpen(false);
            setColumnsIsOpen(false);
        } else if (dropdown === 'columns') {
            setColumnsIsOpen(!columnsIsOpen);
            setSearchByIsOpen(false);
            setRowsPerPageIsOpen(false);
        }


        const buttonRect = event.currentTarget.getBoundingClientRect();
        setMenuPosition({
            top: buttonRect.top + buttonRect.height,
            left: buttonRect.left
        });
    };


    const handleColumnToggle = (column: string) => {
        if (onColumnToggle) {
            onColumnToggle(column);
        }
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch((prevState) => ({
            where: {
                ...prevState.where,
                [searchBy.value]: {
                    ...prevState.where[searchBy.value],
                    value: event.target.value,
                },
            },
        }));
    };

    const handleSearchByChange = (newSearchBy: any) => {
        setSearchBy(newSearchBy);
        setSearch({
            where: {[newSearchBy.value]: {operator: newSearchBy.operator, value: ""}},
        });
        setSearchByIsOpen(false);
    };

    const handleRowsPerPageChange = (rows: number) => () => {
        setRowsPerPage(rows);
        setRowsPerPageIsOpen(false);
    };

    const handleTabClick = (tab: string) => () => {
        setActiveTab && setActiveTab(tab);
    };


    const handleClickOutside = (event: MouseEvent) => {
        if (searchByDropdownRef.current && !searchByDropdownRef.current.contains(event.target as Node) &&
            searchByButtonRef.current && !searchByButtonRef.current.contains(event.target as Node)) {
            setSearchByIsOpen(false);
        }

        if (rowsPerPageDropdownRef.current && !rowsPerPageDropdownRef.current.contains(event.target as Node) &&
            rowsPerPageButtonRef.current && !rowsPerPageButtonRef.current.contains(event.target as Node)) {
            setRowsPerPageIsOpen(false);
        }

        if (columnsDropdownRef.current && !columnsDropdownRef.current.contains(event.target as Node) &&
            columnsRef.current && !columnsRef.current.contains(event.target as Node)) {
            setColumnsIsOpen(false);
        }
    };

    const closeDropdowns = () => {
        setSearchByIsOpen(false);
        setRowsPerPageIsOpen(false);
        setColumnsIsOpen(false);
    };

    const handleScroll = () => {
        closeDropdowns();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    return (
        <div
            className="p-5 space-y-4 flex flex-col bg-white border border-gray-200 shadow:sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
            {tabs !== undefined && (
                <div
                    className="text-sm font-medium text-center text-slate-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                    <ul className="flex flex-wrap -mb-px">
                        {tabs.map((tab) => (
                            <li key={tab}
                                className={`me-2 cursor-pointer inline-block p-2 border-b-2 text-slate-400 ${activeTab === tab ? 'border-cyan-400 text-slate-600 dark:text-gray-200 dark:border-cyan-400' : 'border-transparent'} rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
                                onClick={handleTabClick(tab)}
                                aria-current={activeTab === tab ? 'page' : undefined}
                            >
                                {tab}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div>
                <div
                    className="pt-1 pb-1 h-full space-y-5 flex flex-col bg-white rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                    <div
                        className={`grid grid-cols-1 ${searchable && pageable && columns && blueButton ? 'md:grid-cols-2' : 'md:grid-cols-1'} gap-y-2 md:gap-y-0 md:gap-x-5`}>
                        {searchable && (

                            <div className="relative">
                                <input
                                    type="text"
                                    className="py-[7px] pl-10 pr-3 block w-full bg-gray-50 shadow border border-gray-200 rounded-lg text-sm focus:border-cyan-400 focus:outline-cyan-400
                                               focus:ring-cyan-400 disabled:opacity-50 disabled:pointer-events-none placeholder:text-slate-400 dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400
                                               dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                                    placeholder={`Search by`}
                                    value={search.where[searchBy.value]?.value || ""}
                                    onChange={handleSearchChange}
                                />

                                <div
                                    className="absolute text-slate-400 inset-y-0 start-0 pt-2 pointer-events-none z-10 ps-3.5">
                                    <SearchIcon/>
                                </div>
                                <div className="absolute inset-y-0 end-0  z-10 ps-3.5">

                                    <div className="hs-dropdown relative inline-flex">
                                        <button
                                            id="hs-dropdown-menu"
                                            type="button"
                                            onClick={(event) => toggleDropdown('searchBy', event)}
                                            className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs text-slate-400
                                                       disabled:opacity-50 disabled:pointer-events-none focus:text-cyan-400 dark:bg-neutral-800 dark:border-neutral-700
                                                       dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative"
                                            ref={searchByButtonRef}
                                        >
                                            {searchBy.label}
                                            <ChevronDownIcon/>
                                        </button>
                                        {searchByIsOpen && (
                                            <div
                                                className="fixed   bg-white rounded-l z-10"
                                                style={{
                                                    top: `${menuPosition.top}px`,
                                                    left: `${menuPosition.left }px`,
                                                }}>
                                            <div
                                                className="absolute top-full mt-1 w-[136px] transition-[opacity,margin] duration opacity-100 z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]
                                                            dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900 block"
                                                ref={searchByDropdownRef}
                                                aria-labelledby="hs-dropdown-menu"
                                            >
                                                <div className="p-1">
                                                    {searchByOptions.map((option) => (
                                                        <button
                                                            key={option.value}
                                                            type="button"
                                                            className="w-full flex gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100 disabled:opacity-50
                                                                       disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                                            onClick={() => handleSearchByChange(option)}
                                                        >
                                                            {option.label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>

                            </div>

                        )}
                        <div className="flex lg:justify-end items-center gap-x-2">
                            {pageable && (

                                <div className="hs-dropdown [--auto-close:true] relative inline-flex">
                                    <button
                                        id="hs-dropdown-menu"
                                        type="button"
                                        onClick={(event) => toggleDropdown('rowsPerPage', event)}
                                        className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow
                                                   hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                                                   dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative"
                                        ref={rowsPerPageButtonRef}
                                    >
                                        <BarsDownMenuIcon/>
                                        Rows
                                        <ChevronDownIcon/>
                                    </button>

                                    {rowsPerPageIsOpen && (
                                        <div
                                            className="fixed   bg-white rounded-l z-10"
                                            style={{
                                                top: `${menuPosition.top}px`,
                                                left: `${menuPosition.left }px`,
                                            }}>
                                        <div
                                            className="top-full mt-1 absolute hs-dropdown-menu hs-dropdown-open:opacity-100 w-[110px] transition-[opacity,margin] duration opacity-100 z-10 bg-white rounded-xl
                                                        shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900 block"
                                            ref={rowsPerPageDropdownRef}
                                            aria-labelledby="hs-dropdown-menu"
                                        >
                                            <div className="p-1">
                                                {[10, 25, 50].map((rows) => (
                                                    <button
                                                        key={rows}
                                                        type="button"
                                                        className="w-full flex gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100 disabled:opacity-50
                                                                   disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                                        onClick={handleRowsPerPageChange(rows)}
                                                    >
                                                        {rows} rows
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>

                            )}

                            {showColumnsButton && columns && columnOptions && (
                                <div className="hs-dropdown [--auto-close:true] relative inline-flex">
                                    <button
                                        id="hs-dropdown-menu"
                                        type="button"

                                        onClick={(event) => toggleDropdown('columns', event)}
                                        className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow
                               hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                               dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative"
                                        ref={columnsRef}
                                    >
                                        <ColumnsIcon/>
                                        Columns
                                        <ChevronDownIcon/>
                                    </button>
                                    {columnsIsOpen && columnOptions && (
                                        <div
                                            className="fixed   bg-white rounded-l z-10"
                                            style={{
                                                top: `${menuPosition.top}px`,
                                                left: `${menuPosition.left }px`,
                                            }}>
                                        <div
                                            className="top-full mt-1 absolute hs-dropdown-menu hs-dropdown-open:opacity-100 w-auto min-w-[140px] transition-[opacity,margin] duration opacity-100 z-10 bg-white rounded-xl
                                                        shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900 block"
                                            ref={columnsDropdownRef}
                                            aria-labelledby="hs-dropdown-menu"
                                        >
                                            <div className="p-1 ">
                                                {columnOptions.map((option) => (
                                                    <div key={option.value} className="flex items-center gap-x-1 ml-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={option.isVisible}
                                                            onChange={() => handleColumnToggle(option.value)}

                                                        />
                                                        <label className="w-full  py-1.5  text-[13px] text-gray-800 disabled:opacity-50
                                                                   disabled:pointer-events-none dark:text-neutral-300 focus:outline-none ">
                                                            {option.label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    )}

                                </div>
                            )}
                            {blueButton && (
                                <div>
                                    {blueButton}
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="hs-pro-tabs-dut-all" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-all">
                        <div className="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300
                                        dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                            <div className="pb-5 min-w-full inline-block align-middle">
                                <div>
                                    <table className="w-full border-t border-t-gray-200 divide-y divide-gray-200">
                                        {children}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {pageable && pagy?.pages && (
                        <div className="grid grid-cols-2 items-center gap-y-2 sm:gap-y-0 sm:gap-x-5">
                            <p className="text-sm text-gray-400 dark:text-neutral-200">
                                <span className="font-medium">{pagy.count}</span>
                                <span className="text-gray-400 dark:text-neutral-500"> {recordsName} </span>
                            </p>

                            <nav className="flex justify-end items-center gap-x-1">
                                <button
                                    type="button"
                                    onClick={() => setPage(page === 1 ? 1 : page - 1)}
                                    className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-500 hover:bg-gray-100
                                                disabled:opacity-50 disabled:pointer-events-none focus:outline-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700"
                                >
                                    <ChevronLeftIcon/>
                                    <span aria-hidden="true" className="sr-only">Previous</span>
                                </button>
                                <div className="flex items-center gap-x-1">
                  <span className="min-h-[38px] min-w-[38px] flex justify-center items-center text-gray-500 py-2 px-3 text-sm rounded-lg disabled:opacity-50
                                   disabled:pointer-events-none dark:bg-neutral-700 dark:text-white">{page}</span>
                                    <span
                                        className="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">of</span>
                                    <span
                                        className="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">{pagy?.pages}</span>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => setPage(page === pagy?.pages ? pagy?.pages : page + 1)}
                                    className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-500 hover:bg-gray-100
                    disabled:opacity-50 disabled:pointer-events-none focus:outline-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700"
                                >
                                    <span aria-hidden="true" className="sr-only">Next</span>
                                    <ChevronRightIcon/>
                                </button>
                            </nav>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ({
                    fetchData,
                    pagy,
                    children,
                    searchable,
                    pageable,
                    tabs,
                    searchByOptions,
                    activeTab,
                    setActiveTab,
                    recordsName,
                    blueButton,
                    columnOptions,
                    onColumnToggle,
                }: TableProps) => (
    <SortingProvider>
        <Table
            fetchData={fetchData}
            pagy={pagy}
            searchable={searchable}
            pageable={pageable}
            tabs={tabs}
            searchByOptions={searchByOptions}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            recordsName={recordsName}
            blueButton={blueButton}
            columnOptions={columnOptions}
            onColumnToggle={onColumnToggle}
        >
            {children}
        </Table>
    </SortingProvider>
);
