import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from 'features/auth/authSlice';
import userInvitationReducer from 'features/userInvitation/userInvitationSlice';
import userReducer from 'features/user/userSlice';
import roleReducer from 'features/role/roleSlice';
import sidebarReducer from 'features/sidebar/sidebarSlice';
import locationReducer from 'features/location/locationSlice';
import programReducer from 'features/program/programSlice';
import credentialReducer from 'features/credential/credentialSlice';
import userCredentialReducer from 'features/userCredential/userCredentialSlice';
import payerReducer from 'features/payer/payerSlice';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import hrReducer from 'features/hr/hrSlice';
import userTrainingReducer from 'features/usertraining/userTrainingSlice';
import userHrDocumentReducer from 'features/userHrDocument/userHrDocumentSlice';
import trainingReducer from 'features/training/trainingSlice';
import hrDocumentReducer from 'features/hrDocument/hrDocumentSlice';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        userInvitation: userInvitationReducer,
        user: userReducer,
        role: roleReducer,
        sidebar: sidebarReducer,
        location: locationReducer,
        program: programReducer,
        credential: credentialReducer,
        payer: payerReducer,
        userCredential: userCredentialReducer,
        userTraining: userTrainingReducer,
        userHrDocument: userHrDocumentReducer,
        hr: hrReducer,
        training: trainingReducer,
        hrDocument: hrDocumentReducer,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
