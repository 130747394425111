    import React, { useState} from "react";
    import Drawer from "components/Drawer";
    import CredentialTable from "features/credential/CredentialTable";
    import CredentialForm from "features/credential/CredentialForm";

    const CertificationPage: React.FC = () => {
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        const openDrawer = () => setIsDrawerOpen(true);
        const closeDrawer = () => setIsDrawerOpen(false);

        return (
            <div className="">
                <div className="relative overflow-hidden">
                    <div className="pb-6">
                        <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                            Certifications
                        </div>
                        <div
                            className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                            Manage your company’s certifications
                        </div>
                    </div>
                    <div className="relative">
                        <CredentialTable openDrawer={openDrawer}/>
                        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                            <CredentialForm closeDrawer={closeDrawer}/>
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    };

    export default CertificationPage;
