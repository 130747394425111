import {createSlice} from '@reduxjs/toolkit';
import {PermissionSchemeGroupThunks} from "features/role/permissionSchemeGroupThunks";
import {showToast} from "components/ToastContainer";
import {RoleThunks} from "features/role/roleThunks";

export interface RoleState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    roles?: any;
    selectableRoles?: any;
    permissionSchemeGroups: any;
    pagy?: any;
    role?: any;
    activeTab?: string;
}

const initialState: RoleState = {
    status: 'idle',
    error: null,
    roles: [],
    role: {name: '', nameAlias: ''},
    selectableRoles: [],
    permissionSchemeGroups: [],
    pagy: {},
    activeTab: 'All'
};

const RoleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },


    },
    extraReducers: (builder) => {
        builder
            .addCase(RoleThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(RoleThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(RoleThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.role = action.payload;
                state.roles = [...state.roles, action.payload];
                showToast('Role created successfully', "success");
            })
            .addCase(RoleThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedPayer = action.payload;
                state.role = updatedPayer;

                const index = state.roles.findIndex((item: any) => item.id === updatedPayer.id);
                if (index !== -1) {
                    state.roles[index] = updatedPayer;
                }
                showToast('Role updated successfully', "success");
            })
            .addCase(RoleThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.role = action.payload;
            })
            .addCase(PermissionSchemeGroupThunks.index.fulfilled, (state, action) => {
                state.permissionSchemeGroups = action.payload;

            })
            .addCase(RoleThunks.selectable.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectableRoles = action.payload.data;
            })
            .addCase(RoleThunks.selectable.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.selectable.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(RoleThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(RoleThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(RoleThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = state.roles.filter((role: any) => role.id !== action.payload);
                showToast('Role deleted successfully', 'success');
            })
            .addCase(RoleThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });


    }
});

export const {setRole,setActiveTab} = RoleSlice.actions;
export {initialState as roleInitialState};
export default RoleSlice.reducer;
