import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {UserHrDocumentThunks} from "features/userHrDocument/userHrDocumentThunks";

export interface UserHrDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userHrDocuments?: any;
    pagy?: any;
    userHrDocument?: any;
    isEditing?: boolean;
}

const initialState: UserHrDocumentState = {
    status: 'idle',
    error: null,
    userHrDocuments: [],
    userHrDocument: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false
};

const UserHrDocumentSlice = createSlice({
    name: 'userHrDocument',
    initialState,
    reducers: {
        setUserHrDocument: (state, action) => {
            state.userHrDocument = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(UserHrDocumentThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserHrDocumentThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userHrDocument = action.payload;
                state.userHrDocuments = [...state.userHrDocuments, action.payload];
                showToast('UserHrDocument created successfully', "success");
            })
            .addCase(UserHrDocumentThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(UserHrDocumentThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserHrDocumentThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedUserHrDocument = action.payload;
                state.userHrDocument = updatedUserHrDocument;
                
                const index = state.userHrDocuments.findIndex((item: any) => item.id === updatedUserHrDocument.id);
                if (index !== -1) {
                    state.userHrDocuments[index] = updatedUserHrDocument;
                }
                showToast('UserHrDocument updated successfully', "success");
            })
            .addCase(UserHrDocumentThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(UserHrDocumentThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserHrDocumentThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userHrDocument = action.payload;
            })
            .addCase(UserHrDocumentThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(UserHrDocumentThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserHrDocumentThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userHrDocuments = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(UserHrDocumentThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(UserHrDocumentThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserHrDocumentThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userHrDocuments = state.userHrDocuments.filter((item: any) => item.id !== action.payload);
                showToast('UserHrDocument deleted successfully', "success");
            })
            .addCase(UserHrDocumentThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setUserHrDocument, setIsEditing} = UserHrDocumentSlice.actions;
export {initialState as userHrDocumentInitialState};
export default UserHrDocumentSlice.reducer;
