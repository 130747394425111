export interface UserTrainingInterface {
    id: string;
    name: string;
    licensingAuthority: string;
    status: string;
    expirationDate: string;
    remindUserAt: string;
    notes: string;
    attachment?: any;
    attachmentUrl?: string;
    attachmentSize?: number;
}

export class UserTraining implements UserTrainingInterface {
    id: string;
    name: string;
    licensingAuthority: string;
    status: string;
    expirationDate: string;
    remindUserAt: string;
    notes: string;
    attachment?: any;
    attachmentUrl?: string;
    attachmentSize?: number;

    constructor(data: Partial<UserTrainingInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.licensingAuthority = data.licensingAuthority!;
        this.status = data.status!;
        this.expirationDate = data.expirationDate!;
        this.remindUserAt = data.remindUserAt!;
        this.notes = data.notes!;
        this.attachment = data.attachment;
        this.attachmentUrl = data.attachmentUrl;
        this.attachmentSize = data.attachmentSize;
    }

    toJson(): UserTrainingInterface {
        return {
            id: this.id,
            name: this.name,
            licensingAuthority: this.licensingAuthority,
            status: this.status,
            expirationDate: this.expirationDate,
            remindUserAt: this.remindUserAt,
            notes: this.notes,
            attachment: this.attachment
        };
    }
}
