export interface TrainingInterface {

    id: string;
    name: string;
    description: string;
    expires: boolean;
    remindHr: boolean;
    hrReminderDays: number;
    remindUser: boolean;
    userReminderDays: number;
}

export class Training implements TrainingInterface {
    id: string;
    name: string;
    description: string;
    expires: boolean;
    remindHr: boolean;
    hrReminderDays: number;
    remindUser: boolean;
    userReminderDays: number;

    constructor(data: Partial<TrainingInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.description = data.description!;
        this.expires = data.expires!;
        this.remindHr = data.remindHr!;
        this.hrReminderDays = data.hrReminderDays!;
        this.remindUser = data.remindUser!;
        this.userReminderDays = data.userReminderDays!;
    }

    toJson(): TrainingInterface {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            expires: this.expires,
            remindHr: this.remindHr,
            hrReminderDays: this.hrReminderDays,
            remindUser: this.remindUser,
            userReminderDays: this.userReminderDays
        };
    }
}
