import {createAsyncThunk} from "@reduxjs/toolkit";
import {Payer} from "types/payer";
import {PayerService} from "services/payerService";
import {SearchType} from "types/search";
import {LocationService} from "../../services/locationService";

export class PayerThunks {
    static index = createAsyncThunk(
        'payer/index',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{ [key: string]: any }>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await PayerService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );

    static create = createAsyncThunk(
        'payer/create',
        async (record: Payer) => {
            return await PayerService.create(record);
        }
    );

    static show = createAsyncThunk(
        'payer/show',
        async (id: string) => {
            return await PayerService.show(id);
        }
    );

    static update = createAsyncThunk(
        'payer/update',
        async (record: Payer) => {
            return await PayerService.update(record);
        }
    );

    static delete = createAsyncThunk(
        'payer/delete',
        async (id: string) => {
            return await PayerService.delete(id);
        }
    );
}
