import {createAsyncThunk} from '@reduxjs/toolkit';
import {UserService} from 'services/userService';
import {User} from "types/user";
import {SearchType} from "types/search";
import {TrainingService} from "../../services/trainingService";


export class UserThunks {
    static create = createAsyncThunk(
        'user/create',
        async (user: User) => {
            return await UserService.create(user);
        }
    );

    static register = createAsyncThunk(
        'user/register',
        async ({user, password, token, pinCode, signature, credentials}: {
            user: User,
            password: string,
            token: string,
            pinCode: string,
            signature: string,
            credentials: any
        }) => {
            const response = await UserService.register(user, token, password, pinCode, signature, credentials);
            return {...response, userCredentials: {email: user.email, password}};
        }
    );
    static index = createAsyncThunk(
        'user/index',
        async ({
                   page,
                   search,
                   sortField,
                   sortDirection,
                   rowsPerPage
               }: {
            page?: number,
            search?: SearchType<{ [key: string]: any }>,
            sortField?: string,
            sortDirection?: string,
            rowsPerPage?: number
        } = {}) => {
            return await UserService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );
    static show = createAsyncThunk(
        'user/fetchById',
        async (id: string) => {
            return await UserService.show(id);
        }
    );
    static unlock = createAsyncThunk(
        'user/unlock',
        async (id: string) => {
            return await UserService.unlock(id);
        }
    );
    static unblock = createAsyncThunk(
        'user/unblock',
        async (id: string) => {
            return await UserService.unblock(id);
        }
    );
    static update = createAsyncThunk(
        'user/update',
        async (user: User) => {
            return await UserService.update(user);
        }
    );
    static delete = createAsyncThunk(
        'user/delete',
        async (id: string) => {
            return await UserService.delete(id);
        }
    );
}

