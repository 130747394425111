import React, { useState} from "react";
import Table from "components/table/Table";
import TableHeader from "components/table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {SearchType} from "types/search";
import {useNavigate, useParams} from "react-router-dom";
import {setActiveTab} from "features/hr/hrSlice";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import TrashIcon from "assets/images/icons/TrashIcon";
import {UserHrDocumentThunks} from "features/userHrDocument/userHrDocumentThunks";
import TableRow from "components/table/TableRow";
import TableCell from "components/table/TableCell";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import DotsMenu from "components/DotsMenu";
import {UserHrDocument} from "types/userHrDocument";
import {UserTrainingThunks} from "features/usertraining/userTrainingThunks";
import WhiteButton from "components/WhiteButton";
import DownloadIcon from "assets/images/icons/DownloadIcon";
import {setIsEditing} from "features/userHrDocument/userHrDocumentSlice";



const UserHrDocumentsTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const userHrDocuments = useSelector((state: RootState) => state.userHrDocument.userHrDocuments);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.userHrDocument.pagy);
    const params = useParams<{userId: string}>();
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const [selectedAttachmentUrl, setSelectedAttachmentUrl] = useState<string | null>(null);

    interface VisibleColumnsType {
        name: boolean;
        status: boolean;
        expDate: boolean;
        notes: boolean;
        completedBy: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        status: true,
        expDate: true,
        completedBy: true,
        notes: true
    });

    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
        {label: 'Status', value: 'status', operator: 'like'},
        {label: 'Exp Date', value: 'expDate', operator: 'like'},
        {label: 'Completed By', value: 'completedBy', operator: 'like'},
        {label: 'Notes', value: 'notes', operator: 'like'},

    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        const { userId } = params;

        search.where.user_id = {
            operator: '==',
            value: userId as string
        };

        await dispatch(UserHrDocumentThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleSelectDownload = () => {
        if (selectedAttachmentUrl) {
            window.open(selectedAttachmentUrl, '_blank');
        }
    };

    const handleDelete = async (id: string) => {
        await dispatch(UserHrDocumentThunks.delete(id));
        setDotsMenuIsOpen(null);
    };

    const handleEdit = async (id: string) => {
        await dispatch(UserHrDocumentThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();
    }
    const handleDownload = (attachmentUrl: string) => {
        if (attachmentUrl) {
            window.open(attachmentUrl, '_blank')
        }
    };
    const handleOpenDrawer = async (id: string) => {
        await dispatch(UserTrainingThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();
    };


    return (
        <Table
            tabs={['HR Documents', 'Certifications', 'Trainings']}
            activeTab={'HR Documents'}
            fetchData={fetchData}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            pagy={pagy}
            recordsName={'HR Documents'}
            columns={true}
            blueButton={<WhiteButton onClick={handleSelectDownload} label={''} icon={<DownloadIcon/>}/>}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                                      dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status" />}
                {visibleColumns.expDate && <TableHeader label="Exp Date" sortBy="expDate"/>}
                {visibleColumns.completedBy && <TableHeader label="Completed by"/>}
                {visibleColumns.notes && <TableHeader label="Notes"/>}
                <TableHeader label=""/>
            </tr>
            </thead>
            <tbody>
            {userHrDocuments.map((userHrDocument: UserHrDocument) =>{
                const userOptions = [
                    {
                        label: 'Edit',
                        onClick: () => handleEdit(userHrDocument.id),
                        hoverClass: "hover:bg-cyan-100"
                    },
                    ...(userHrDocument.status === 'Complete' ? [{
                        label: 'Download',
                        onClick: () => handleDownload(userHrDocument.attachmentUrl || ''),
                        hoverClass: "hover:bg-cyan-100"
                    }] : []),

                    ...(userHrDocument.status === 'Missing' ? [{
                        label: 'Upload',
                        onClick: () => handleEdit(userHrDocument.id),
                        hoverClass: "hover:bg-cyan-100"
                    }] : []),
                    {
                        label: 'Delete',
                        onClick: () => handleDelete(userHrDocument.id),
                        icon: <TrashIcon/>,
                        hoverClass: "hover:bg-red-100 hover:text-red-800"
                    }
                ];
                return (
                    <TableRow key={userHrDocument.id}>
                        <td scope="col" className="pt-3.5 flex justify-center">
                            <input
                                type="checkbox"
                                className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                           dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                onClick={(e) => e.stopPropagation()}/>
                        </td>
                        {visibleColumns.name &&
                            <TableCell>
                                <div className=" relative group">
                                    <div className="w-full flex items-center gap-x-3">
                                        <div className="flex-grow">
                                        <span className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                              onClick={()=>handleEdit(userHrDocument.id)}
                                        >
                                            {userHrDocument.name}

                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        }
                        {visibleColumns.status &&
                            <TableCell>
                                <span
                                    className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${userHrDocument.status === 'Complete' ? 'bg-green-100 text-green-800' : ''}
                                    ${userHrDocument.status === 'Missing' ? 'bg-red-100 text-red-800' : ''}
                                    `}
                                >
                                    {userHrDocument.status === 'Complete' && <CheckCircleIcon className="mr-2"/>}
                                    {userHrDocument.status === 'Missing' && <XCircleIcon className="mr-2"/>}

                                    <div className="pl-1">
                                        {userHrDocument.status}
                                    </div>
                                </span>
                            </TableCell>
                        }
                        {visibleColumns.expDate && <TableCell>{userHrDocument.expirationDate || 'NA' }</TableCell>}
                        {visibleColumns.completedBy && <TableCell>{userHrDocument.completedBy}</TableCell>}
                        {visibleColumns.notes &&
                            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                <div style={{maxWidth: '150px'}}
                                     className="whitespace-nowrap overflow-hidden text-ellipsis">
                                    {userHrDocument.notes}
                                </div>
                            </TableCell>}

                        <TableCell>
                            <DotsMenu options={userOptions}/>
                        </TableCell>
                    </TableRow>
                );}
            )}
            </tbody>
        </Table>
    );
};

export default UserHrDocumentsTable;
