import { createSlice } from '@reduxjs/toolkit';
import { showToast } from "components/ToastContainer";
import {UserInvitationThunks} from 'features/userInvitation/userInvitationThunk';
import {UserThunks} from "../user/userThunks";

export interface UserInvitationState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    invitation?: any;
    userInvitations?: any;
    pagy?: any;
    userInvitation?: any;
}

const initialState: UserInvitationState = {
    status: 'idle',
    error: null,
    invitation: null,
    userInvitations: [],
    pagy: null,
    userInvitation: null
};

const userInvitationSlice = createSlice({
    name: 'userInvitation',
    initialState,
    reducers: {
        setInvitation: (state, action) => {
            state.invitation = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(UserInvitationThunks.index.pending, (state) => {
                state.userInvitation = null;
                state.status = 'loading';
            })
            .addCase(UserInvitationThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userInvitation = action.payload;
            })
            .addCase(UserInvitationThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userInvitations = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(UserInvitationThunks.showByToken.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserInvitationThunks.showByToken.fulfilled, (state, action) => {
                state.invitation = action.payload;
                state.status = 'succeeded';
            })
            .addCase(UserInvitationThunks.showByToken.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(UserInvitationThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserInvitationThunks.create.fulfilled, (state, action) => {
                console.log('action.payload', action.payload)
                state.status = 'succeeded';
                state.userInvitations = [...state.userInvitations, action.payload];
                UserThunks.index();
                showToast('Staff Invitation sent', 'success');
            })
            .addCase(UserInvitationThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
                state.userInvitations = [...state.userInvitations, action.payload];
            })
            .addCase(UserInvitationThunks.cancel.fulfilled, (state) => {
                state.status = 'succeeded';
                showToast('Staff Invitation cancelled', 'success');
            })
            .addCase(UserInvitationThunks.resend.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.userInvitations.findIndex((item: any) => item.id === action.payload.id);
                if (index !== -1) {
                    state.userInvitations[index] = action.payload;
                }
                showToast('Staff Invitation resent', 'success');
            })



    }
});

export const { setInvitation } = userInvitationSlice.actions;

export { initialState as userInvitationInitialState };
export default userInvitationSlice.reducer;
