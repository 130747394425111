import React, {useEffect} from 'react';
import {FaUser} from "react-icons/fa";
import PencilSquareIcon from "assets/images/icons/PencilSquareIcon";
import SubmitButton from "components/SubmitButton";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {User} from "types/user";
import LoadingSpinner from "components/LoadingSpinner";
import {setIsEditing} from "features/user/userSlice";
import PhoneNumberDisplay from "components/PhoneNumberDisplay";
import SSNDisplay from "components/SSNDisplay";


interface UserDetailsProps {
    onClose: () => void,
    closeDrawer?: () => void
}

const ProfileDetails: React.FC<UserDetailsProps> = ({onClose, closeDrawer}) => {
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user.user as User);

    const handleEditing = () => () => {
        dispatch(setIsEditing(true));
    }

    useEffect(() => {
        dispatch(setIsEditing(false));
    });

    return (
        <div className="flex flex-col h-screen">
            {user !== null &&

                <div className="flex-1 flex flex-col">
                    <div className="flex-1 ">
                        <div className="pt-8 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                            <div className="pl-6 self-stretch flex flex-row  gap-3">
                                <div className="flex-shrink-0 w-14 h-14 mt-1.5 bg-cyan-400 rounded-lg flex justify-center items-center">
                                    <FaUser className="text-white size-8"/>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                                        {user?.firstName} {user?.lastName}
                                    </div>
                                    <div className="text-sm font-light tracking-normal leading-tight text-zinc-400">
                                        Employee ID: {user?.id}
                                    </div>
                                    <div
                                        className="text-sm w-max font-light tracking-normal leading-tight text-zinc-400">
                                        Last Login: {user?.lastLogin}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-2 px-4 sm:px-6">
                            <div className="h-full ">
                                <div>
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 dark:border-neutral-700">
                                        <dt className="col-span-1">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Company email:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2  flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {user?.email}
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Personal email:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {user?.personalEmail}
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                SSN:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2">
                                            <div className="flex items-center gap-x-2">
                                                <span className="text-sm text-gray-800 dark:text-neutral-200">
                                                    <SSNDisplay value={user?.ssn}/>
                                                </span>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Phone number:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                <PhoneNumberDisplay value={user?.phoneNumber}/>
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Role:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {user?.role?.nameAlias}
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Signature:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 items-center">
                                            {user.signature ? (
                                                <img src={user.signature} alt="User Signature"
                                                     className="w-32"/>
                                            ) : (
                                                <p className="text-sm text-gray-500 dark:text-neutral-500">No signature
                                                    available</p>
                                            )}
                                        </dd>
                                    </dl>

                                </div>

                            </div>
                        </div>
                    </div>
                    <footer className="flex-shrink-0 px-7 pb-4 flex justify-end gap-5">
                        <div className="border-t border-slate-200 w-full">
                            <div
                                className="flex justify-center items-center gap-x-2 pb-4">
                                <SubmitButton onClick={handleEditing()} label={<><PencilSquareIcon/> {'Edit my profile'} </>}
                                              disabled={false}/>
                            </div>
                            <div
                                className="flex  justify-center items-center gap-x-2 pb-4">
                                <button type="submit" onClick={onClose}
                                        className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                                    Never mind
                                </button>
                            </div>
                        </div>
                    </footer>
                </div>
            }
            {user === null &&
                <div className="h-full"><LoadingSpinner/></div>
            }
        </div>
    );
};

export default ProfileDetails;
