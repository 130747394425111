import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {TrainingThunks} from "./trainingThunks";

export interface TrainingState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    trainings?: any;
    pagy?: any;
    training?: any;
    isEditing?: boolean;
    activeTab?: string;
}

const initialState: TrainingState = {
    status: 'idle',
    error: null,
    trainings: [],
    training: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false,
    activeTab: 'All'
};

const TrainingSlice = createSlice({
    name: 'training',
    initialState,
    reducers: {
        setTraining: (state, action) => {
            state.training = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(TrainingThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(TrainingThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.training = action.payload;
                state.trainings = [...state.trainings, action.payload];
                showToast('Training created successfully', "success");
            })
            .addCase(TrainingThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(TrainingThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(TrainingThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedTraining = action.payload;
                state.training = updatedTraining;
                
                const index = state.trainings.findIndex((item: any) => item.id === updatedTraining.id);
                if (index !== -1) {
                    state.trainings[index] = updatedTraining;
                }
                showToast('Training updated successfully', "success");
            })
            .addCase(TrainingThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(TrainingThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(TrainingThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.training = action.payload;
            })
            .addCase(TrainingThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(TrainingThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(TrainingThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.trainings = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(TrainingThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(TrainingThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(TrainingThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.trainings = state.trainings.filter((item: any) => item.id !== action.payload);
                showToast('Training deleted successfully', "success");
            })
            .addCase(TrainingThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setTraining, setIsEditing, setActiveTab} = TrainingSlice.actions;
export {initialState as trainingInitialState};
export default TrainingSlice.reducer;
