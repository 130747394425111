import React, {useEffect, useRef, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Program} from "types/program";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {ProgramThunks} from "features/program/programThunks";
import {setIsEditing, setProgram} from "features/program/programSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import {setActiveTab} from "features/user/userSlice";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import DotsMenu from "components/DotsMenu";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import TrashIcon from "assets/images/icons/TrashIcon";
import {LocationThunks} from "features/location/locationThunks";
import {Location} from "types/location";

const ProgramTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const programs = useSelector((state: RootState) => state.program.programs);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.program.pagy);
    const activeTab = useSelector((state: RootState) => state.user.activeTab);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const location = useSelector((state: RootState) => state.location.locations);
    const locationOptions = location.map((location: Location) => ({
        value: location.id,
        label: location.name
    }));

    interface VisibleColumnsType {
        name: boolean;
        abbreviationName: boolean;
        description: boolean;
        status: boolean;
        locationIds: boolean;

    }

    const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
        name: true,
        abbreviationName: true,
        description: true,
        status: true,
        locationIds: true
    });

    const columnOptions = [
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Description', value: 'description', isVisible: visibleColumns.description},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Abbreviation', value: 'abbreviationName', isVisible: visibleColumns.abbreviationName},
        {label: 'Applicable locations', value: 'locationsIds', isVisible: visibleColumns.locationIds}

    ];
    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'}

    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab.toLowerCase()
            };
        } else {
            search.where.status = {
                operator: '==',
                value: ''
            }
        }
        await dispatch(ProgramThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        dispatch(setIsEditing(false));
        openDrawer();
        await dispatch(ProgramThunks.show(id));
    };

    const handleOpenNewProgramDrawer = () => {
        dispatch(setProgram({
            id: '',
            name: '',
            abbreviationName: '',
            description: '',
            locationIds: [],
            status: '',
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };
    const handleDeactivate = (programId: string) => {
        const program = new Program({id: programId, status: 'inactive'})
        dispatch(ProgramThunks.update(program) as any);
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (programId: string) => {
        const program = new Program({id: programId, status: 'active'})
        dispatch(ProgramThunks.update(program) as any);
        setDotsMenuIsOpen(null);
    };

    const handleDelete = (programId: string) => {
        dispatch(ProgramThunks.delete(programId) as any);
        setDotsMenuIsOpen(null);
    };
    const handleEdit = (programId: string) => {
        dispatch(setIsEditing(true));
        openDrawer();
        dispatch(ProgramThunks.show(programId) as any);

    }
    useEffect(() => {
        const loadExternalData = async () => {
            try {
                await dispatch(LocationThunks.index());
            } catch (err) {
            }
        };
        loadExternalData();
    }, []);


    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            recordsName={'Programs'}
            columnOptions={columnOptions}
            searchable={true}
            columns={true}
            blueButton={<BlueButton onClick={handleOpenNewProgramDrawer} label="" icon={<PlusIcon/>}/>}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.abbreviationName && <TableHeader label="Abbreviation" sortBy="abbreviatioName"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.locationIds && <TableHeader label="Applicable locations" sortBy="locationIds"/>}
                <TableHeader label=""/>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {programs.map((program: Program) => {
                    const userOptions = [];
                    userOptions.push({
                        label: "Edit",
                        onClick: () => handleEdit(program.id),
                        hoverClass: "hover:bg-cyan-100",
                    });

                    if (program.status === "Active") {
                        userOptions.push({
                            label: "Deactivate",
                            onClick: () => handleDeactivate(program.id),
                            hoverClass: "hover:bg-red-100 hover:text-red-800",
                        });
                    }
                    if (program.status === "Inactive") {
                        userOptions.push({
                            label: "Activate",
                            onClick: () => handleReactivate(program.id),
                            hoverClass: "hover:bg-cyan-100 ",
                        });
                    }
                userOptions.push({
                    label: "Delete",
                    onClick: () => handleDelete(program.id),
                    icon: <TrashIcon/>,
                    hoverClass: "hover:bg-red-100 hover:text-red-800",
                });
                    return (
                        <TableRow
                            key={program.id}
                        >
                            <td scope="col" className="pt-3.5 flex justify-center">
                                <input
                                    type="checkbox"
                                    className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800
                                               dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    onClick={(e) => e.stopPropagation()}/>
                            </td>
                            {visibleColumns.name &&
                                <TableCell>
                                    <div className=" relative group">
                                        <div className="w-full flex items-center gap-x-3">
                                            <div className="flex-grow">
                                        <span
                                            className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                            onClick={handleOpenDrawer(program.id)}
                                        >
                                            {program.name}

                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>}
                            {visibleColumns.abbreviationName && <TableCell>{program.abbreviationName}</TableCell>}
                            {visibleColumns.description && (
                                <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                    <div style={{ maxWidth: '150px' }} className="whitespace-nowrap overflow-hidden text-ellipsis">
                                        {program.description}
                                    </div>
                                </TableCell>
                            )}

                            {visibleColumns.status && (
                                <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${program.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {program.status === 'Active' ? <CheckCircleIcon className="mr-2"/> :

                                    <XCircleIcon className="mr-2"/>}

                                <div className="pl-1">
                                    {program.status === 'Active' ? program.status : 'Inactive'}
                                </div>
                            </span>
                                </TableCell>)}
                            {visibleColumns.locationIds && (
                                <TableCell>
                                    {program.locationIds?.map((locationId: string) => {
                                        const location = locationOptions.find((option: { value: string, label: string }) => option.value === locationId);
                                        return location ? location.label : '';
                                    }).join(', ')}
                                </TableCell>
                            )}
                            <TableCell>
                                <DotsMenu options={userOptions}/>
                            </TableCell>

                        </TableRow>
                    )
                }
            )}
            </tbody>
        </Table>
    );
};

export default ProgramTable;
