import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {Role} from "types/role";
import {PermissionSchemeGroup} from "types/permissionSchemeGroup";
import {Permission} from "types/permission";
import SubmitButton from "components/SubmitButton";
import {RoleThunks} from "features/role/roleThunks";
import {PermissionScheme} from "types/permissionScheme";
import DescriptionInput from "components/inputs/DescriptionInput";
import RadioInput from "components/inputs/RadioInput";
import XIcon from "../../assets/images/icons/XIcon";

interface RoleFormProps {
    id?: string;
    closeDrawer: () => void;

}

const RoleForm: React.FC<RoleFormProps> = ({id, closeDrawer}) => {
    const role = useSelector((state: RootState) => state.role.role);
    const permissionSchemeGroups = useSelector((state: RootState) => state.role.permissionSchemeGroups);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedRole, setUpdatedRole] = useState({...role});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid =
            updatedRole.nameAlias &&
            updatedRole.description
        setIsSaveDisabled(!isFormValid);
    }, [
        updatedRole.nameAlias,
        updatedRole.description,
        updatedRole.permissions,
    ]);

    useEffect(() => {
        setUpdatedRole({...role})
        setIsSaveDisabled(!role?.nameAlias);
    }, [role]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedRole({...updatedRole, [id]: value});
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, checked} = e.target;
        const [subjectClass, _, action] = id.split('_');

        if (!subjectClass) return;
        const permissions = updatedRole.permissions ? [...updatedRole.permissions] : [];
        if (checked) {
            permissions.push({subjectClass, action} as Permission);
            if (action === 'manage') {
                const permissionSchemeGroup = permissionSchemeGroups.find((permissionSchemeGroup: PermissionSchemeGroup) => permissionSchemeGroup.subjectClass === subjectClass);
                if (permissionSchemeGroup) {
                    permissionSchemeGroup.permissionSchemes?.forEach((permission: PermissionScheme) => {
                        const index = permissions.findIndex((p: Permission) => p.subjectClass === subjectClass && p.action === permission.action);
                        if (index === -1) {
                            permissions.push({subjectClass, action: permission.action} as Permission);
                        }
                    })
                }
            }
        } else {
            const index = permissions.findIndex((permission: Permission) => permission.subjectClass === subjectClass
                && permission.action === action);
            if (index !== -1) {
                if (permissions[index].id) {
                    const permission = {...permissions[index], _destroy: true};
                    permissions.splice(index, 1);
                    permissions.push(permission);
                } else {
                    permissions.splice(index, 1);
                }

            }
        }

        setUpdatedRole({...updatedRole, permissions});
    };

    const handleRadioChange = (field: string, value: boolean) => {
        setUpdatedRole((prevState: Role) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (role?.id) {
            await dispatch(RoleThunks.update(new Role(updatedRole))).then(() => closeDrawer());
        } else {
            await dispatch(RoleThunks.create(new Role(updatedRole))).then(() => closeDrawer());
        }
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-[130px] px-6 pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {role?.id ? 'Edit role' : 'Create new role'}
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {role?.id ? 'Edit' : 'Create'} a role and assign custom permissions
                        </div>
                    </div>
                    <button onClick={closeDrawer} className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="p-4 sm:p-7 px-4 sm:px-6 w-full">
                    <form onSubmit={handleSubmit}>
                        <div className="flex w-full flex-col space-y-6 items-center">
                            <div className="w-full">
                                <TextInput
                                    id="nameAlias"
                                    type="text"
                                    label="Name"
                                    value={updatedRole?.nameAlias}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <DescriptionInput
                                    id="description"
                                    type="text"
                                    label="Description"
                                    value={updatedRole?.description}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="w-full">
                                <div className="flex justify-between items-center mb-3">
                                    <h3 className="text-slate-800 text-sm font-semibold dark:text-neutral-200">
                                        Permissions
                                    </h3>
                                    <div
                                        className="flex justify-center items-center space-x-8 text-xs pr-3 font-normal text-slate-400 dark:text-neutral-200">
                                        <span>View</span>
                                        <span>Create</span>
                                        <span>Update</span>
                                        <span>Delete</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-white dark:bg-neutral-800  rounded-lg shadow border divide-y divide-slate-200 ">

                                    {permissionSchemeGroups.map((permissionSchemeGroup: PermissionSchemeGroup) => (
                                        <div key={permissionSchemeGroup.subjectClass}
                                             className="flex justify-between items-center mb-3 space-y-3 ">
                                                        <span
                                                            className="text-xs font-normal pl-3 pt-3 text-slate-600 dark:text-neutral-200">
                                                            {permissionSchemeGroup.nameAlias}
                                                        </span>
                                            <div className="flex space-x-14 pr-6  ">
                                                {['view', 'create', 'update', 'delete'].map((action) => (
                                                    <div key={action} className="flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            id={`${permissionSchemeGroup.subjectClass}_permission_${action}`}
                                                            name={`permission_${action}`}
                                                            checked={updatedRole.permissions?.find((p: Permission) => p.subjectClass === permissionSchemeGroup.subjectClass && p.action === action && !p._destroy) || updatedRole.name === 'owner'}
                                                            onChange={handleCheckboxChange}
                                                            disabled={updatedRole.permissions?.find((p: Permission) => p.subjectClass === permissionSchemeGroup.subjectClass && p.action === 'manage' && !p._destroy) && action !== 'manage' || updatedRole.name === 'owner'}
                                                            className="border-gray-200 rounded text-cyan-400 focus:ring-cyan-300 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <RadioInput
                                label="Selectable"
                                options={
                                    [
                                        {id: 'selectableYes', label: 'Yes', value: true},
                                        {id: 'selectableNo', label: 'No', value: false}
                                    ]
                                }
                                selectedValue={updatedRole.selectable}
                                onChange={(value) => handleRadioChange('selectable', value)}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      disabled={isSaveDisabled}
                                      data-hs-overlay="#hs-basic-modal"
                                      label={updatedRole.id ? 'Save role' : 'Create role'}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={closeDrawer}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>

    );
}

export default RoleForm;
