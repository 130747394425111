import React, {useEffect, useState} from "react";
import { RoleThunks} from "features/role/roleThunks";
import {useDispatch} from "react-redux";
import {AppDispatch} from "store/store";
import Drawer from "components/Drawer";
import {PermissionSchemeGroupThunks} from "features/role/permissionSchemeGroupThunks";
import RoleTable from "features/role/RoleTable";
import RoleForm from "features/role/RoleForm";
import {setRole} from "features/role/roleSlice";


const RolesPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    const handleNewRoleOpenDrawer = () => {
        dispatch(setRole({
            id: '',
            name: '',
            nameAlias: '',
            description: '',
            selectable: undefined,
            permissionSchemeGroupId: [],
            rolePermissions: []
        }));
        openDrawer();
    };


    useEffect(() => {
        const loadSchemePermissionGroups = async () => {
            try {
                await dispatch(PermissionSchemeGroupThunks.index());
            } catch (err) {
            }
        };
        const loadRoles = async () => {
            try {
                await dispatch(RoleThunks.index());
            } catch (err) {
            }
        }
        loadRoles();
        loadSchemePermissionGroups();
    }, [dispatch]);

    return (
        <div className="">
            <div className="relative overflow-hidden">
                <div className="pb-6">
                    <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                        Roles
                    </div>
                    <div
                        className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                        Manage your company’s roles
                    </div>
                </div>
                <div className="relative  overflow-hidden">
                    <RoleTable openDrawer={openDrawer} handleNewRoleOpenDrawer={handleNewRoleOpenDrawer} />
                    <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                        <RoleForm closeDrawer={closeDrawer} />
                    </Drawer>
                </div>

            </div>
        </div>
    )
        ;

};

export default RolesPage;
