import React, {useEffect, useState} from "react";
import UserTable from "features/user/UserTable";
import {RoleThunks} from "features/role/roleThunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import Drawer from "components/Drawer";
import UserDetails from "features/user/UserDetails";
import UserEditForm from "features/user/UserEditForm";
import InvitationsForm from "features/userInvitation/InvitationsForm";
import StaffInvitationTable from "features/userInvitation/StaffInvitationTable";
import UserInvitationDetails from "features/userInvitation/UserInvitationDetails";

const UserPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isNewDrawerOpen, setIsNewDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    const openNewDrawer = () => setIsNewDrawerOpen(true);
    const closeNewDrawer = () => setIsNewDrawerOpen(false);
    const isEditing = useSelector((state: RootState) => state.user.isEditing);
    const activeTab = useSelector((state: RootState) => state.user.activeTab);

    useEffect(() => {
        const loadRoles = async () => {
            try {
                await dispatch(RoleThunks.selectable());
            } catch (err) {
            }
        };
        loadRoles();
    }, []);


    return (
        <div className="">
            <div className="relative overflow-hidden">
                <div className="pb-6">
                    <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                        Users
                    </div>
                    <div
                        className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                        Manage the users and user access
                    </div>
                </div>
                <div className="">
                    <div className="relative overflow-hidden">
                        {activeTab === 'Invitations' && (
                            <>
                                <StaffInvitationTable openDrawer={openDrawer} openNewDrawer={openNewDrawer}/>
                                <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                                    <UserInvitationDetails onClose={closeDrawer}/>
                                </Drawer>
                            </>
                        )}

                        {activeTab !== 'Invitations' && (
                            <>
                                <UserTable openDrawer={openDrawer} openNewDrawer={openNewDrawer} isNewDrawerOpen={isNewDrawerOpen}/>
                                <Drawer
                                    isOpen={isDrawerOpen} onClose={closeDrawer}>
                                    {isEditing ? <UserEditForm onClose={closeDrawer}/> :
                                        <UserDetails onClose={closeDrawer}/>}
                                </Drawer>
                            </>
                        )}
                        <Drawer isOpen={isNewDrawerOpen} onClose={closeNewDrawer}>
                            <InvitationsForm closeDrawer={closeNewDrawer} isDrawerOpen={isNewDrawerOpen}/>
                        </Drawer>

                    </div>
                </div>
            </div>
        </div>

                );
                };

                export default UserPage;
