import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {HrDocument} from "types/hrDocument";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {HrDocumentThunks} from "./hrDocumentThunks";
import {setIsEditing, setHrDocument} from "features/hrDocument/hrDocumentSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";

const HrDocumentTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const hrDocuments = useSelector((state: RootState) => state.hrDocument.hrDocuments);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.hrDocument.pagy);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();

    const [visibleColumns, setVisibleColumns] = useState({
        id: true,
        name: true,
        description: true
    });

    interface VisibleColumnsType {
        id: boolean,
        name: boolean,
        description: boolean
    }
    const searchByOptions = [
        {label: 'ID', value: 'id', operator: '=='},
        {label: 'Name', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'ID', value: 'id', isVisible: visibleColumns.id},
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Description', value: 'description', isVisible: visibleColumns.description}
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        await dispatch(HrDocumentThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        openDrawer();
        await dispatch(HrDocumentThunks.show(id));
    };

    const handleOpenNewHrDocumentDrawer = () => {
        dispatch(setHrDocument({
            id: '',
            name: '',
            description: '',
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };
    const handleDelete = async (id: string) => {
        await dispatch(HrDocumentThunks.delete(id));
        setDotsMenuIsOpen(null);
    };

    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            recordsName={'HrDocuments'}
            searchable={true}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={handleOpenNewHrDocumentDrawer} label="" icon={<PlusIcon/>}/>}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                      dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.id && <TableHeader label="ID" sortBy="id"/>}
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {hrDocuments.map((hrDocument: HrDocument) => {
                const userOptions = [
                    {label: 'Edit', onClick: handleOpenDrawer(hrDocument.id)},
                    {label: 'Delete', onClick: () => handleDelete(hrDocument.id)}
                ];
                return (
                    <TableRow
                        key={hrDocument.id}
                    >
                        <td scope="col" className="pt-3.5 flex justify-center">
                            <input
                                type="checkbox"
                                className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                       dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                onClick={(e) => e.stopPropagation()}/>
                        </td>
                        {visibleColumns.id && <TableCell>{hrDocument.id}</TableCell>}
                        {visibleColumns.name &&
                            <TableCell>
                                <div className=" relative group">
                                    <div className="w-full flex items-center gap-x-3">
                                        <div className="flex-grow">
                                        <span
                                            className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                            onClick={handleOpenDrawer(hrDocument.id)}
                                        >
                                            {hrDocument.name}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>}
                        {visibleColumns.description && <TableCell>{hrDocument.description}</TableCell>}
                        <TableCell>
                            <DotsMenu options={userOptions}/>
                        </TableCell>
                    </TableRow>
                )
                }
            )}
            </tbody>
        </Table>
    );
};

export default HrDocumentTable;
