export interface HrDocumentInterface {
    id: string;
    name: string;
    description: string;
}

export class HrDocument implements HrDocumentInterface {
    id: string;
    name: string;
    description: string;

    constructor(data: Partial<HrDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.description = data.description!;
    }

    toJson(): HrDocumentInterface {
        return {
            id: this.id,
            name: this.name,
            description: this.description
        };
    }
}
