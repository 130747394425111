import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {UserTrainingThunks} from "features/usertraining/userTrainingThunks";


export interface UserTrainingState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userTrainings?: any;
    pagy?: any;
    userTraining?: any;
    isEditing?: boolean;
}

const initialState: UserTrainingState = {
    status: 'idle',
    error: null,
    userTrainings: [],
    userTraining: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false
};

const UserTrainingSlice = createSlice({
    name: 'userTraining',
    initialState,
    reducers: {
        setUserTraining: (state, action) => {
            state.userTraining = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(UserTrainingThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserTrainingThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userTraining = action.payload;
                state.userTrainings = [...state.userTrainings, action.payload];
                showToast('UserTraining created successfully', "success");
            })
            .addCase(UserTrainingThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(UserTrainingThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserTrainingThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedUserTraining = action.payload;
                state.userTraining = updatedUserTraining;

                const index = state.userTrainings.findIndex((item: any) => item.id === updatedUserTraining.id);
                if (index !== -1) {
                    state.userTrainings[index] = updatedUserTraining;
                }
                showToast('UserTraining updated successfully', "success");
            })
            .addCase(UserTrainingThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(UserTrainingThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserTrainingThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userTraining = action.payload;
            })
            .addCase(UserTrainingThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(UserTrainingThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserTrainingThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userTrainings = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(UserTrainingThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(UserTrainingThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserTrainingThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userTrainings = state.userTrainings.filter((item: any) => item.id !== action.payload);
                showToast('UserTraining deleted successfully', "success");
            })
            .addCase(UserTrainingThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setUserTraining, setIsEditing} = UserTrainingSlice.actions;
export {initialState as userTrainingInitialState};
export default UserTrainingSlice.reducer;
