import React, {useEffect, useState} from 'react';
import {AppDispatch, RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import TextInput from "components/inputs/TextInput";
import {Role} from "types/role";
import {User} from "types/user";
import {RoleThunks} from "features/role/roleThunks";
import {validateEmail} from "utils/validators";
import {UserThunks} from "features/user/userThunks";
import SSNInput from "components/inputs/SSNInput";
import PhoneNumberInput from "components/inputs/PhoneNumberInput";
import {showToast} from "components/ToastContainer";
import {FaUser} from "react-icons/fa";
import CustomSelect from "components/inputs/CustomSelect";
import SubmitButton from "../../components/SubmitButton";


interface UserEditFormProps {
    onClose: () => void;
}

const UserEditForm: React.FC<UserEditFormProps> = ({onClose}) => {
    const user = useSelector((state: RootState) => state.user.user);
    const roles = useSelector((state: RootState) => state.role.roles);
    const selectableRoles = useSelector((state: RootState) => state.role.selectableRoles);
    const dispatch = useDispatch<AppDispatch>();
    const [emailError, setEmailError] = useState('');
    const [ssn, setSSN] = useState(user?.ssn);
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
    const tabs = ['Profile', 'Activity', 'HR Documents', 'Training'];
    const [activeTab, setActiveTab] = useState('Profile');


    const [formData, setFormData] = useState({
        ...user,
        roleId: user?.role?.id,
        supervisorUserIds: user?.supervisors?.reduce((acc: { [key: string]: number }, supervisor: any) => {
            if (supervisor.role && supervisor.user) {
                acc[supervisor.role.name] = supervisor.user.id;
            }
            return acc;
        }, {}) || {}
    });


    useEffect(() => {
        setSSN(user?.ssn);
        setPhoneNumber(user?.phoneNumber);
        setFormData({
            ...user,
            roleId: user?.role?.id,
            supervisorUserIds: user?.supervisors?.reduce((acc: { [key: string]: number }, supervisor: any) => {
                if (supervisor.role && supervisor.user) {
                    acc[supervisor.role.name] = supervisor.user.id;
                }
                return acc;
            }, {}) || {}
        });
    }, [user]);


    useEffect(() => {
        const loadSelectData = async () => {
            try {
                await dispatch(RoleThunks.index());
                await dispatch(RoleThunks.selectable());
            } catch (err) {
            }
        };
        loadSelectData();
    }, [dispatch]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {id, value} = e.target;
        if (id === 'email') {
            if (validateEmail(value)) {
                setEmailError('');
            } else {
                setEmailError('Please enter a valid email address.');
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSupervisorChange = (role: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            supervisorUserIds: {
                ...prevData.supervisorUserIds,
                [role]: Number(value)
            }
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (emailError) {
            showToast("Please enter a valid email address.", "error");
            return;
        }

        try {
            const parsedData = {
                ...formData,
                supervisorUserIds: Object.values(formData.supervisorUserIds).map((id) => id.toString()),
            }
            await dispatch(UserThunks.update(new User(parsedData))).unwrap();
        } catch (error) {
        }
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-full flex flex-col overflow-y-auto overflow-hidden [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                    <div
                        className="pt-8  bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                        <div className=" pl-6 self-stretch flex flex-row gap-3">
                            <div
                                className="flex-shrink-0 w-14 h-14 mt-1.5 bg-cyan-400 rounded-lg flex justify-center items-center">
                                <FaUser className="text-white size-8"/>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                                    {user?.firstName} {user?.lastName}
                                </div>
                                <div className="text-sm font-light tracking-normal leading-tight text-zinc-400">
                                    Employee ID: {user?.id}
                                </div>
                                <div className="text-sm w-max font-light tracking-normal leading-tight text-zinc-400">
                                    Last Login: {user?.lastLogin}
                                </div>
                            </div>
                        </div>
                        <div
                            className="pt-4 text-sm font-medium text-center text-slate-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                            <ul className="flex flex-wrap -mb-px">
                                {tabs.map((tab) => (
                                    <li key={tab}
                                        className={`me-2 cursor-pointer inline-block p-4 border-b-2 ${activeTab === tab ? 'border-cyan-400 text-cyan-600 dark:text-gray-200 dark:border-cyan-400' : 'border-transparent'} rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
                                        onClick={() => setActiveTab(tab)}
                                        aria-current={activeTab === tab ? 'page' : undefined}
                                    >
                                        {tab}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div id="hs-pro-tabs-dut-Profile" className={activeTab === 'Profile' ? 'block' : 'hidden'}>
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col p-6 space-y-6 items-center">

                                <div className="grid grid-cols-2 gap-4 w-full ">
                                    <TextInput
                                        id="firstName"
                                        type="text"
                                        label="First Name"
                                        value={formData.firstName || ""}
                                        onChange={handleChange}
                                    />
                                    <TextInput
                                        id="lastName"
                                        type="text"
                                        label="Last Name"
                                        value={formData.lastName || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full">
                                    <div className="flex flex-col gap-[0.625rem] w-full ">
                                        <label htmlFor="roleId"
                                               className="w-[487px] block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                            Role
                                        </label>
                                        <CustomSelect
                                            options={roles.map((role: Role) => ({
                                                value: role.id,
                                                label: role.nameAlias
                                            }))}
                                            onChange={(selectedOption) => {
                                                setFormData((prevData) => ({
                                                    ...prevData,
                                                    ['roleId']: selectedOption.value
                                                }));
                                            }}
                                            value={formData.roleId || ''}
                                        />
                                    </div>
                                </div>
                                {selectableRoles.map((role: Role) => (
                                    <div key={role.id} className="w-full">
                                        <div className="flex flex-col w-full gap-[0.625rem]">
                                            <label htmlFor="roleId"
                                                   className="w-[487px] block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                                {role.nameAlias}
                                            </label>
                                            <CustomSelect
                                                options={role.users?.map((user: User) => ({
                                                    value: user.id,
                                                    label: user.name
                                                }))}
                                                onChange={(selectedOption) => {
                                                    handleSupervisorChange(role.name, selectedOption.value);
                                                }}
                                                value={formData.supervisorUserIds[role.name] || ''}
                                            />

                                        </div>
                                    </div>
                                ))}
                                <div className="w-full">
                                    <TextInput
                                        id="email"
                                        type="text"
                                        label="Company email"
                                        value={formData.email || ""}
                                        onChange={handleChange}
                                        error={emailError}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextInput
                                        id="personalEmail"
                                        type="text"
                                        label="Personal email"
                                        value={formData.personalEmail || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full">
                                    <PhoneNumberInput
                                        id="phoneNumber"
                                        label="Phone Number"
                                        value={formData.phoneNumber || ""}
                                        onChange={handleChange}
                                        phoneNumber={phoneNumber}
                                        setPhoneNumber={setPhoneNumber}
                                    />
                                </div>
                                <div className="w-full pb-5 ">
                                    <SSNInput
                                        id="ssn"
                                        label="SSN"
                                        ssn={ssn}
                                        setSSN={setSSN}
                                        value={formData.ssn || ""}
                                        onChange={handleChange}
                                    />
                                </div>

                            </div>
                        </form>
                        <footer className="flex-shrink-0 px-6 pb-4  pt-5 flex justify-end gap-5">
                            <div className="border-t border-slate-200 w-full">
                                <div
                                    className="flex w-fulljustify-center items-center gap-x-2 pb-4">
                                    <SubmitButton onClick={handleSubmit}
                                                  label={"Update user profile"}
                                                  disabled={false}
                                    />
                                </div>
                                <div
                                    className="flex w-full justify-center items-center gap-x-2 pb-4">
                                    <button type="submit" onClick={onClose}
                                            className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                                        Never mind
                                    </button>
                                </div>
                            </div>
                        </footer>

                    </div>
                </div>

            </div>
        </div>
    )
        ;
};

export default UserEditForm;
