import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Payer} from "types/payer";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {PayerThunks} from "./payerThunks";
import {setIsEditing, setPayer} from "features/payer/payerSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import {setActiveTab} from "features/payer/payerSlice";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import AlertIcon from "assets/images/icons/AlertIcon";
import DotsMenu from "components/DotsMenu";
import TrashIcon from "assets/images/icons/TrashIcon";
import {LocationThunks} from "features/location/locationThunks";
import {Location} from "types/location";

const PayerTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const payers = useSelector((state: RootState) => state.payer.payers);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.payer.pagy);
    const activeTab = useSelector((state: RootState) => state.payer.activeTab);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const location = useSelector((state: RootState) => state.location.locations);
    const locationOptions = location.map((location: Location) => ({
        value: location.id,
        label: location.name
    }));

    const payerTypeOptions: { [key: string]: string } = {
        'insurance': 'Insurance',
        'non_insurance': 'Non insurance',
        'self_pay': 'Self pay',
    };

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        address: true,
        payerType: true,
        phoneNumber: true,
        externalId: true,
        status: true,
        locationIds: true,
        description: true
    });

    interface VisibleColumnsType {
        name: boolean,
        address: boolean,
        payerType: boolean,
        phoneNumber: boolean,
        externalId: boolean,
        status: boolean
        locationIds: boolean,
        description: boolean
    }

    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},

    ];

    const columnOptions = [
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'External ID', value: 'externalId', isVisible: visibleColumns.externalId},
        {label: 'Type', value: 'payerType', isVisible: visibleColumns.payerType},
        {label: 'Description', value: 'description', isVisible: visibleColumns.description},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Address', value: 'address', isVisible: visibleColumns.address},
        {label: 'Phone number', value: 'phoneNumber', isVisible: visibleColumns.phoneNumber},
        {label: 'Applicable locations', value: 'locationIds', isVisible: visibleColumns.locationIds},

    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab.toLowerCase()
            };
        } else {
            search.where.status = {
                operator: '==',
                value: ''
            }
        }
        await dispatch(PayerThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };


    const handleDeactivate = (payerId: string) => {
        const payer = new Payer({id: payerId, status: 'inactive'})
        dispatch(PayerThunks.update(payer) as any);
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (payerId: string) => {
        const payer = new Payer({id: payerId, status: 'active'})
        dispatch(PayerThunks.update(payer) as any);
        setDotsMenuIsOpen(null);
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        dispatch(setIsEditing(false));
        openDrawer();
        await dispatch(PayerThunks.show(id));
    };

    const handleOpenNewPayerDrawer = () => {
        dispatch(setPayer({
            name: '',
            description: '',
            address: '',
            payerType: '',
            phoneNumber: '',
            externalId: '',
            status: '',
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    const handleDelete = (payerId: string) => {
        dispatch(PayerThunks.delete(payerId) as any);
        setDotsMenuIsOpen(null);
    }
    const handleEdit = (locationId: string) => {
        dispatch(setIsEditing(true));
        openDrawer();
        dispatch(PayerThunks.show(locationId) as any);
    }

    useEffect(() => {
        const fetchLocations = async () => {
            await dispatch(LocationThunks.index());
        }
        fetchLocations();
    }, []);

    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            pageable={true}
            recordsName={'Payers'}
            searchable={true}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={handleOpenNewPayerDrawer} label="" icon={<PlusIcon/>}/>}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.externalId && <TableHeader label="External ID" sortBy="externalId"/>}
                {visibleColumns.payerType && <TableHeader label="Type" sortBy="type"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.address && <TableHeader label="Address"/>}
                {visibleColumns.phoneNumber && <TableHeader label="Phone number"/>}
                {visibleColumns.locationIds && <TableHeader label="Applicable locations" sortBy="locationIds"/>}
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {payers.map((payer: Payer) => {
                    const userOptions = [];
                    userOptions.push({
                        label: "Edit",
                        onClick: () => handleEdit(payer.id),
                        hoverClass: "hover:bg-cyan-100",
                    });

                    if (payer.status === "Active") {
                        userOptions.push({
                            label: 'Deactivate',
                            onClick: () => handleDeactivate(payer.id),
                            hoverClass: "hover:bg-red-100 hover:text-red-800",
                        });
                    }
                    if (payer.status === "Inactive") {
                        userOptions.push({
                            label: 'Activate',
                            onClick: () => handleReactivate(payer.id),
                            hoverClass: "hover:bg-cyan-100",
                        });
                    }
                    userOptions.push({
                        label: "Delete",
                        onClick: () => handleDelete(payer.id),
                        icon: <TrashIcon/>,
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                    });

                    return (
                        <TableRow
                            key={payer.id}
                        >
                            <td scope="col" className="pt-3.5 flex justify-center">
                                <input
                                    type="checkbox"
                                    className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    onClick={(e) => e.stopPropagation()}/>
                            </td>
                            {visibleColumns.name && <TableCell>
                                <div className=" relative group">
                                    <div className="w-full flex items-center gap-x-3">
                                        <div className="flex-grow">
                                        <span
                                            className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                            onClick={handleOpenDrawer(payer.id)}
                                        >
                                            {payer.name}

                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>}
                            {visibleColumns.externalId && <TableCell>{payer.externalId}</TableCell>}
                            {visibleColumns.payerType && <TableCell>{payerTypeOptions[payer.payerType]}</TableCell>}
                            {visibleColumns.description && (
                                <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                    <div style={{maxWidth: '150px'}}
                                         className="whitespace-nowrap overflow-hidden text-ellipsis">
                                        {payer.description}
                                    </div>
                                </TableCell>
                            )}
                            {visibleColumns.status && (
                                <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${payer.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {payer.status === 'Active' ? <CheckCircleIcon className="mr-2"/> :
                                    <XCircleIcon className="mr-2"/>}

                                <div className="pl-1">
                                    {payer.status === 'Active' ? payer.status : 'Inactive'}
                                </div>
                            </span>
                                </TableCell>)}
                            {visibleColumns.address && <TableCell>{payer.address}</TableCell>}
                            {visibleColumns.phoneNumber && <TableCell>{payer.phoneNumber}</TableCell>}
                            {visibleColumns.locationIds && (
                                <TableCell>
                                    {payer.locationIds?.map((locationId: string) => {
                                        const location = locationOptions.find((option: {
                                            value: string,
                                            label: string
                                        }) => option.value === locationId);
                                        return location ? location.label : '';
                                    }).join(', ')}
                                </TableCell>
                            )}
                            <TableCell>
                                <DotsMenu options={userOptions}/>
                            </TableCell>
                        </TableRow>
                    )
                }
            )}
            </tbody>
        </Table>
    );
};

export default PayerTable;
