import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Training} from "types/training";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {TrainingThunks} from "features/training/trainingThunks";
import {setIsEditing, setTraining} from "features/training/trainingSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import TrashIcon from "assets/images/icons/TrashIcon";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";
import {setActiveTab} from "features/training/trainingSlice";

const TrainingTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const trainings = useSelector((state: RootState) => state.training.trainings);
    const activeTab = useSelector((state: RootState) => state.training.activeTab);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.training.pagy);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();

    const [visibleColumns, setVisibleColumns] = useState({
        id: true,
        name: true,
        description: true,
        expires: true,
        remindHr: true,
        hrReminderDays: true
    });

    interface VisibleColumnsType {
        id: boolean,
        name: boolean,
        description: boolean,
        expires: boolean,
        remindHr: boolean,
        hrReminderDays: boolean
    }

    const searchByOptions = [
        {label: 'ID', value: 'id', operator: '=='},
        {label: 'Name', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'ID', value: 'id', isVisible: visibleColumns.id},
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Description', value: 'description', isVisible: visibleColumns.description},
        {label: 'Expires', value: 'expires', isVisible: visibleColumns.expires},
        {label: 'Remind HR', value: 'remindHr', isVisible: visibleColumns.remindHr},
        {label: 'HR reminder days', value: 'hrReminderDays', isVisible: visibleColumns.hrReminderDays}
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        await dispatch(TrainingThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        await dispatch(TrainingThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();

    };

    const handleOpenNewTrainingDrawer = () => {
        dispatch(setTraining({
            id: '',
            name: '',
            description: '',
            expires: '',
            remindHr: '',
            hrReminderDays: '',
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };
    const handleDelete = async (id: string) => {
        await dispatch(TrainingThunks.delete(id));
        setDotsMenuIsOpen(null);
    }

    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            recordsName={'Trainings'}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            searchable={true}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={handleOpenNewTrainingDrawer} label="" icon={<PlusIcon/>}/>}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.id && <TableHeader label="ID" sortBy="id"/>}
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
                {visibleColumns.expires && <TableHeader label="Expires"/>}
                {visibleColumns.remindHr && <TableHeader label="Remind Hr"/>}
                {visibleColumns.hrReminderDays && <TableHeader label="Hr reminder days"/>}
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {trainings.map((training: Training) => {
                const userOptions = [
                    {label: 'Edit', onClick: handleOpenDrawer(training.id.toString()), hoverClass: "hover:bg-cyan-100 "},
                    {label: 'Delete', onClick: () => handleDelete(training.id), icon: <TrashIcon/>, hoverClass: "hover:bg-red-100 hover:text-red-800"}
                ];
                return (
                <TableRow
                    key={training.id}
                >
                    <td scope="col" className="pt-3.5 flex justify-center">
                        <input
                            type="checkbox"
                            className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            onClick={(e) => e.stopPropagation()}/>
                    </td>
                    {visibleColumns.id && <TableCell>{training.id}</TableCell>}
                    {visibleColumns.name && <TableCell>
                        <div className=" relative group">
                            <div className="w-full flex items-center gap-x-3">
                                <div className="flex-grow">
                                        <span className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                              onClick={handleOpenDrawer(training.id)}
                                        >
                                            {training.name}

                                        </span>
                                </div>
                            </div>
                        </div>
                    </TableCell>}
                    {visibleColumns.description && <TableCell>{training.description}</TableCell>}
                    {visibleColumns.expires && <TableCell>{training.expires ? 'Yes' : 'No'}</TableCell>}
                    {visibleColumns.remindHr && <TableCell>{training.remindHr ? 'Yes' : 'No'}</TableCell>}
                    {visibleColumns.hrReminderDays && <TableCell>{training.hrReminderDays}</TableCell>}
                    <TableCell>
                        <DotsMenu options={userOptions}/>
                    </TableCell>
                </TableRow>
                )}
            )}
            </tbody>
        </Table>
    );
};

export default TrainingTable;
