import {createAsyncThunk} from "@reduxjs/toolkit";
import {Training} from "types/training";
import {TrainingService} from "services/trainingService";
import {SearchType} from "types/search";

export class TrainingThunks {
    static index = createAsyncThunk(
        'training/index',
        async ({
                   page,
                   search,
                   sortField,
                   sortDirection,
                   rowsPerPage
               }: {
            page?: number,
            search?: SearchType<{ [key: string]: any }>,
            sortField?: string,
            sortDirection?: string,
            rowsPerPage?: number
        } = {}) => {
            return await TrainingService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );

    static create = createAsyncThunk(
        'training/create',
        async (record: Training) => {
            return await TrainingService.create(record);
        }
    );

    static show = createAsyncThunk(
        'training/show',
        async (id: string) => {
            return await TrainingService.show(id);
        }
    );

    static update = createAsyncThunk(
        'training/update',
        async (record: Training) => {
            console.log('record', record);
            return await TrainingService.update(record);
        }
    );

    static delete = createAsyncThunk(
        'training/delete',
        async (id: string) => {
            return await TrainingService.delete(id);
        }
    );
}
