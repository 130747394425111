import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {UserCredentialThunks} from "features/userCredential/userCredentialThunks";

export interface UserCredentialState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userCredentials?: any;
    pagy?: any;
    userCredential?: any;
    isEditing?: boolean;
}

const initialState: UserCredentialState = {
    status: 'idle',
    error: null,
    userCredentials: [],
    userCredential: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false
};

const UserCredentialSlice = createSlice({
    name: 'userCredential',
    initialState,
    reducers: {
        setUserCredential: (state, action) => {
            state.userCredential = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(UserCredentialThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserCredentialThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userCredential = action.payload;
                state.userCredentials = [...state.userCredentials, action.payload];
                showToast('UserCredential created successfully', "success");
            })
            .addCase(UserCredentialThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(UserCredentialThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserCredentialThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedUserCredential = action.payload;
                state.userCredential = updatedUserCredential;
                
                const index = state.userCredentials.findIndex((item: any) => item.id === updatedUserCredential.id);
                if (index !== -1) {
                    state.userCredentials[index] = updatedUserCredential;
                }
                showToast('UserCredential updated successfully', "success");
            })
            .addCase(UserCredentialThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(UserCredentialThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserCredentialThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userCredential = action.payload;
            })
            .addCase(UserCredentialThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(UserCredentialThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserCredentialThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userCredentials = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(UserCredentialThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(UserCredentialThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserCredentialThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userCredentials = state.userCredentials.filter((item: any) => item.id !== action.payload);
                showToast('UserCredential deleted successfully', "success");
            })
            .addCase(UserCredentialThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setUserCredential, setIsEditing} = UserCredentialSlice.actions;
export {initialState as userCredentialInitialState};
export default UserCredentialSlice.reducer;
