    import apiClient from "services/apiClient";
    import {HrDocument} from "types/hrDocument";
    import {SearchType} from "types/search";
    import {buildUrlWithParams} from "utils/apiUtils";
    
    export class HrDocumentService {
        static async index(page: number, search: SearchType<{ [key: string]: any}>, sortField: string, sortDirection: string, rowsPerPage: number) {
            const url = buildUrlWithParams({
                baseUrl: 'api/v1/hr_documents',
                page: page,
                search: search,
                sortField: sortField,
                sortDirection: sortDirection,
                rowsPerPage: rowsPerPage,
            });

            const response = await apiClient.get(url);
            return response.data;
        }
    
        static async create(record: HrDocument) {
            const response = await apiClient.post("api/v1/hr_documents", {hrDocument: record.toJson()});
            return response.data;
        }
    
        static async show(id: string) {
            const response = await apiClient.get(`api/v1/hr_documents/${id}`);
            return response.data;
        }
    
        static async update(record: HrDocument) {
            console.log(record)
            const response = await apiClient.patch(`api/v1/hr_documents/${record.id}`, {hrDocument: record.toJson()});
            return response.data;
        }
    
        static async delete(id: string) {
            const response = await apiClient.delete(`api/v1/hr_documents/${id}`);
            return response.data;
        }
    }
