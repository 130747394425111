import React, {} from 'react';
import SecondStepVerification from "features/auth/components/SecondStepVerification";
import {useSelector} from "react-redux";
import {RootState} from "store/store";
import useSecondStepVerification from "hooks/useSecondStepVerification";
import SubmitButton from "components/SubmitButton";

const SecondStepVerificationPage = () => {
    const { pin, firstInputRef, error, handlePinChange, handleKeyDown, handlePaste, handleSubmit, isFormValid, status } = useSecondStepVerification();
    const userEmail = useSelector((state: RootState) =>
        state.auth.userCredentials != null ? state.auth.userCredentials?.email : "",
    );


    return (
        <div>
            <div className="flex flex-col pr-20 pl-20 pt-20  max-w-120 md:min-w-96 sm:min-w-64">
                <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
                    Two-factor authentication
                </div>
                <div className="text-sm font-light tracking-normal  w-80 leading-5 text-zinc-400">
                    <span className="leading-tight">Authentication code sent to your email: </span>
                    <span className="text-cyan-400 leading-tight">{userEmail}</span>
                    <span className="leading-tight">. Enter the code below.</span>
                </div>
                <SecondStepVerification
                    pin={pin}
                    firstInputRef={firstInputRef}
                    error={error}
                    handlePinChange={handlePinChange}
                    handleKeyDown={handleKeyDown}
                    handlePaste={handlePaste}
                />
                <div className="pt-[159px]" >
                <SubmitButton
                    className={"w-80 py-2.5 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"}
                    onClick={handleSubmit}
                    label="Done"
                    disabled={status === 'loading' || !isFormValid}
                />
                </div>

            </div>
        </div>
    );
};

export default SecondStepVerificationPage;
