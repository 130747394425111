import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {PayerThunks} from "features/payer/payerThunks";

export interface PayerState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    payers?: any;
    pagy?: any;
    payer?: any;
    isEditing?: boolean;
    activeTab?: string;

}

const initialState: PayerState = {
    status: 'idle',
    error: null,
    payers: [],
    payer: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false,
    activeTab: 'All'
};

const PayerSlice = createSlice({
    name: 'payer',
    initialState,
    reducers: {
        setPayer: (state, action) => {
            state.payer = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(PayerThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(PayerThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.payer = action.payload;
                state.payers = [...state.payers, action.payload];
                showToast('Payer created successfully', "success");
            })
            .addCase(PayerThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(PayerThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(PayerThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedPayer = action.payload;
                state.payer = updatedPayer;
                
                const index = state.payers.findIndex((item: any) => item.id === updatedPayer.id);
                if (index !== -1) {
                    state.payers[index] = updatedPayer;
                }
                showToast('Payer updated successfully', "success");
            })
            .addCase(PayerThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(PayerThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(PayerThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.payer = action.payload;
            })
            .addCase(PayerThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(PayerThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(PayerThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.payers = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(PayerThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(PayerThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(PayerThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.payers = state.payers.filter((item: any) => item.id !== action.payload);
                showToast('Payer deleted successfully', "success");
            })
            .addCase(PayerThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setPayer, setIsEditing, setActiveTab} = PayerSlice.actions;
export {initialState as payerInitialState};
export default PayerSlice.reducer;
