import {Role} from "./role";

export interface UserInterface {
    id?: string;
    email: string;
    personalEmail: string;
    name: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role?: Role;
    ssn: string;
    supervisorUserIds?: string[];
    supervisors?: any[];
    status?: string;
    lastLogin?: string;
    roleId?: string;
    locked?: boolean;
    blocked?: boolean;
    signature?: string;
    location?: any;
    credentials?: any;
}

export class User implements UserInterface {
    id: string;
    email: string;
    name: string;
    personalEmail: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: Role;
    ssn: string;
    supervisorUserIds: string[];
    supervisors?: any[];
    status: any;
    lastLogin: string;
    roleId: string;
    locked: boolean;
    blocked: boolean;
    signature: string;
    location: any;
    credentials: any;

    constructor(data: Partial<UserInterface> = {}) {
        this.id = data.id || '';
        this.email = data.email || '';
        this.personalEmail = data.personalEmail || '';
        this.name = data.name || '';
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.phoneNumber = data.phoneNumber || '';
        this.role = new Role(data.role || {});
        this.supervisors = data.supervisors || [];
        this.ssn = data.ssn || '';
        this.supervisorUserIds = data.supervisorUserIds || [];
        this.status = data.status || 'active';
        this.lastLogin = data.lastLogin || '';
        this.roleId = data.roleId || '';
        this.locked = data.locked || false;
        this.blocked = data.blocked || false;
        this.signature = data.signature || '';
        this.location = data.location || {};
        this.credentials = data.credentials || {};
    }

    static fromJson(data: UserInterface): User {
        return new User(data);
    }
    toJson(): UserInterface {
        const rawUser = {
            id: this.id,
            name: this.name,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber !== undefined ? this.phoneNumber.replace(/\D/g, '') : undefined,
            ssn: this.ssn !== undefined ? this.ssn.replace(/\D/g, '') : undefined,
            supervisorUserIds: this.supervisorUserIds,
            supervisors: this.supervisors,
            status: this.status,
            personalEmail: this.personalEmail,
            roleId: this.roleId,
        };

        const filteredUser = Object.fromEntries(
            Object.entries(rawUser).filter(([key, value]) =>
                value !== undefined &&
                value !== null &&
                value !== '' &&
                !(Array.isArray(value) && value.length === 0)
            )
        );

        return filteredUser as UserInterface;
    }
}
