import React from "react";

interface BlueButtonProps {
  onClick: () => void;
  label?: string;
  icon?: React.ReactNode;
}

const WhiteButton: React.FC<BlueButtonProps> = ({onClick, label, icon}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="py-2 px-2 inline-flex items-center gap-x-1.5 text-xs rounded-lg shadow
                       disabled:opacity-50 focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                       dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative
                       border border-slate-200  text-slate-800 hover:bg-cyan-100
                       disabled:pointer-events-none disabled:bg-zinc-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
      {icon}
      {label}
    </button>
  );
};

export default WhiteButton;
