import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserCredential} from "types/userCredential";
import {UserCredentialService} from "services/userCredentialService";
import {SearchType} from "types/search";

export class UserCredentialThunks {
    static index = createAsyncThunk(
        'userCredential/index',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{ [key: string]: any }>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await UserCredentialService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );

    static create = createAsyncThunk(
        'userCredential/create',
        async (record: UserCredential) => {
            return await UserCredentialService.create(record);
        }
    );

    static show = createAsyncThunk(
        'userCredential/show',
        async (id: string) => {
            return await UserCredentialService.show(id);
        }
    );

    static update = createAsyncThunk(
        'userCredential/update',
        async (record: UserCredential) => {
            return await UserCredentialService.update(record);
        }
    );

    static delete = createAsyncThunk(
        'userCredential/delete',
        async (id: string) => {
            return await UserCredentialService.delete(id);
        }
    );
}
