    import apiClient from "services/apiClient";
    import {UserTraining} from "types/userTraining";
    import {SearchType} from "types/search";
    import {buildUrlWithParams} from "utils/apiUtils";
    
    export class UserTrainingService {
        static async index(page: number, search: SearchType<{ [key: string]: any}>, sortField: string, sortDirection: string, rowsPerPage: number) {
            const url = buildUrlWithParams({
                baseUrl: 'api/v1/user_trainings',
                page: page,
                search: search,
                sortField: sortField,
                sortDirection: sortDirection,
                rowsPerPage: rowsPerPage,
            });

            const response = await apiClient.get(url);
            return response.data;
        }
    
        static async create(record: UserTraining) {
            const response = await apiClient.post("api/v1/user_trainings", {userTraining: record.toJson()},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        }
    
        static async show(id: string) {
            const response = await apiClient.get(`api/v1/user_trainings/${id}`);
            return response.data;
        }
    
        static async update(record: UserTraining) {
            const response = await apiClient.patch(`api/v1/user_trainings/${record.id}`, {userTraining: record.toJson()},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        );
            return response.data;
        }
    
        static async delete(id: string) {
            const response = await apiClient.delete(`api/v1/user_trainings/${id}`);
            return response.data;
        }
    }
